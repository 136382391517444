import React from 'react';
import './Toolbox.css';
import {CatalogTypesNonTranslated,CatalogAppTypesNonTranslated} from '../Diagram/CatalogTypesTranslated';
import typeReader from "../Diagram/ReadTypeTU";
import data from "../../assets/node-icons";
import Tooltip from '@material-ui/core/Tooltip';
import Popup from "reactjs-popup";
import Form from '@rjsf/material-ui';
import ToolboxParams from './ToolboxParams/ToolboxParams';
import ToolboxExport from './ToolboxExportImport/ToolboxExport';
import ToolboxImport from './ToolboxExportImport/ToolboxImport';
import SelectFilter from './../UI/SelectFilter/SelectFilter';
import ToolboxApps from './ToolboxApps/ToolboxApps';
import axios from '../../axios-base';

import { useTranslation } from 'react-i18next';

const schema = JSON.parse('{"title": "Paramètres du flux","type": "object","properties": {"name": {"type": "string","title": "Nom"},"guid": {"type": "string","title": "GUID","description": "suffixe de l\'url d\'appel du scénario","disabled":"true"},"response_datas": {   "type": "string",        "title": "données à renvoyer"      },      "response_type": {        "type": "string", "enum": ["","json","xml"],       "title": "Type de réponse"      }    }  }');
const params = JSON.parse('{"response_type": "json","name": "récupération des clients Exact","guid": "3EEFD56C-5F52-49A3-A75B-44D2A9F7F7B4","response_datas": "¤1"}');
const style = {width : '500px'};


function Toolbox (props) {

    /*const formFields = [
        {
            size: 12,
            field: (
                <TextField
                    label="Name"
                    name="name"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={name}
                />)
        },
    ];*/

    const { t } = useTranslation('diagram');

    /*const formatedItems = types.map(itKey => {

        let tuImg = data[itKey.code];

        return <div className="tu-item col-12" key={itKey.id} onClick={() => props.createNode(itKey.id)}>{itKey.name} <img className={"tuIcon"} src={tuImg} /></div>

    });*/

    //const filteredItems  = <SelectFilter data={CatalogTypesNonTranslated()} createNode={props.createNode} />;
    //const filteredAppItems  = <SelectFilter data={CatalogAppTypesTranslated()} createNode={props.createNode} />;
    let saveButton = <i className="material-icons Toolbox-button play-button zooming" onClick={() => props.saveDiag()}>save</i>;
    if (props.saving) {
        saveButton = <i className="material-icons Toolbox-button play-button rotating">autorenew</i>;
    }

    //Lien exécution du flux dans un nouvel onglet
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };
    const playExecuteHTTP = axios.defaults.baseURL + '/lance/scenario/' + props.toolboxParamsInfos.guid;
    const playButton = <i className="material-icons Toolbox-button play-button zooming" onClick={() => {openInNewTab(playExecuteHTTP)}}>play_circle_filled</i>;


    return (
        <div className="Toolbox-container">            
            
            <Popup position="top"
                       contentStyle = {{width : '500px', height : '600px'}}
                   closeOnDocumentClick={true}
                       trigger={<Tooltip title={t("toolbox.importerFlux")}><i className="material-icons Toolbox-button play-button zooming">get_app</i></Tooltip>}>

                    {close => (
                        <React.Fragment>
                            <a className="close" onClick={close}>
                                &times;
                            </a>

                            <ToolboxImport importScenario={props.importScenario} closePopup={close}/>
                            
                        </React.Fragment>
                    )}
                </Popup>
                <Popup position="top"
                       contentStyle = {{width : '500px', height : '600px'}}
                       trigger={<Tooltip title={t("toolbox.exporterFlux")}><i className="material-icons Toolbox-button play-button zooming">publish</i></Tooltip>}>

                    {close => (
                        <React.Fragment>
                            <a className="close" onClick={close}>
                                &times;
                            </a>

                            <ToolboxExport exportScenario={props.exportScenario} closePopup={close}/>
                            
                        </React.Fragment>
                    )}
                </Popup>
                <Popup position="top"
                       contentStyle = {style}
                       trigger={<Tooltip title={t("toolbox.parametrerFlux")}><i className="material-icons Toolbox-button play-button zooming">settings</i></Tooltip>}>

                    {close => (
                        <React.Fragment>
                            <a className="close" onClick={close}>
                                &times;
                            </a>

                            <ToolboxParams toolboxParamsInfos={props.toolboxParamsInfos} updateFluxParams={props.updateFluxParams} closePopup={close}/>
                        </React.Fragment>
                    )}
                </Popup>
                

            <Popup position="top"
                   contentStyle = {{width : '400px', height: '500px', overflowY:'auto',overflowX:'hidden'}}
                   trigger={<Tooltip title={t("toolbox.ajoutTU")}>
                       <label className="Toolbox-button add-button zooming" htmlFor="toggle" ><i className="material-icons play-button">add_circle</i></label>
                   </Tooltip>}>

                {close => (
                    <React.Fragment>
                        <a className="close" onClick={close}>
                            &times;
                        </a>
                        <div className="Toolbox-menu row">
                            <SelectFilter data={CatalogTypesNonTranslated()} createNode={props.createNode} closePopup={close}/>
                        </div>
                    </React.Fragment>
                )}
            </Popup>

            <Popup position="top"
                   contentStyle = {{width : '400px', height: '500px', overflowY:'auto',overflowX:'hidden'}}
                   closeOnDocumentClick={true}
                   trigger={<Tooltip title={t("toolbox.ajoutApp")}>
                       <label className="Toolbox-button extension-button zooming" htmlFor="toggle2" ><i className="material-icons play-button">extension</i></label>
                   </Tooltip>}>

                {close => (
                    <React.Fragment>
                        <a className="close" onClick={close}>
                            &times;
                        </a>
                        <div className="Toolbox-menu row">
                            <ToolboxApps appTypes={CatalogAppTypesNonTranslated()} createNode={props.createNode} closePopup={close} />
                        </div>
                    </React.Fragment>
                )}
            </Popup>

            <Tooltip title={t("toolbox.executeFlux")}>
                {playButton}
            </Tooltip>
            <Tooltip title={t("toolbox.saveFlux")}>
                {saveButton}
            </Tooltip>
        </div>
    );
};

export default Toolbox;