import React from 'react';
import * as Yup from 'yup';
import { Switches, Select, makeRequired } from 'mui-rff';
import CustomTextField from '../CustomTextField';
import Condition from '../Condition';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form'

export const TuXrpFlexputcdeschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const required = makeRequired(TuXrpFlexputcdeschema);


function TuXrpFlexputcde(props) {
    const { t } = useTranslation('diagram');

    const helperText = t("param.requis");

    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let HttpConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={HttpConnexions}
                />
            </Grid>;
    }

    return (
        <React.Fragment>

            {connexionField}

            <Grid item xs={12} key={1}>
                <Select
                    label={t("param.method")}
                    name="method"
                    data={[
                        { label: '', value: '' },
                        { label: 'GET', value: 'GET' },
                        { label: 'PUT', value: 'PUT' },
                        { label: 'DELETE', value: 'DELETE' },

                    ]}
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.serveurname")}
                    name="serveurname"
                    id="serveurname"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.instancename")}
                    name="instancename"
                    id="instancename"
                />
            </Grid>
            <Grid item xs={12} key={4}>
                <CustomTextField
                    label={t("param.baseendpointurl")}
                    name="baseendpointurl"
                    id="baseendpointurl"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <CustomTextField
                    label={t("param.endpointsversion")}
                    name="endpointsversion"
                    id="endpointsversion"
                />
            </Grid>
            <Grid item xs={12} key={6}>
                <CustomTextField
                    label={t("param.entity")}
                    name="entity"
                    id="entity"
                />
            </Grid>
            <Grid item xs={12} key={7}>
                <CustomTextField
                    label={t("param.filter")}
                    name="filter"
                    id="filter"
                />
            </Grid>
            <Grid item xs={12} key={8}>
                <CustomTextField
                    label={t("param.expand")}
                    name="expand"
                    id="expand"
                />
            </Grid>
            <Grid item xs={12} key={9}>
                <CustomTextField
                    label={t("param.fieldselect")}
                    name="fieldselect"
                    id="fieldselect"
                />
            </Grid>
            <Grid item xs={12} key={10}>
                <CustomTextField
                    label={t("param.top")}
                    name="top"
                    id="top"
                />
            </Grid>
            <Grid item xs={12} key={11}>
                <CustomTextField
                    label={t("param.skip")}
                    name="skip"
                    id="skip"
                />
            </Grid>
            <Grid item xs={12} key={12}>
                <Switches
                    label="Mode libre"
                    name="map"
                    // required={required.available}
                    data={{ label: 'Mode libre', value: 'libre' }}
                    
                // helperText={helperText}
                />
            </Grid>
            <Condition when="map" is={true}>
                <Grid item xs={12} key={12}>
                    <CustomTextField
                        label={t("param.datas")}
                        name="datas"
                        id="datas"
                        value="gergege"
                    />
                </Grid>
            </Condition>
            
        </React.Fragment>
    );
}

export default TuXrpFlexputcde;
