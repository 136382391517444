import {NodeModel } from '@projectstorm/react-diagrams';
import {OFCPortModel} from '../OFCLink/OFCPortModel';

/**
 * Example of a custom model using pure javascript
 */
export class OFCNodeModel extends NodeModel {
	constructor(options = {}) {
		super({
			...options,
			type: 'ofc-node',
            typeTU: options.typeTU,
            key: options.key,
			name: options.name,
			id_node: options.id_node,
			ordre: options.ordre,
			id_boucle: options.id_boucle,
			previous_node: options.previous_node,
            next_node: options.next_node,
		});
		this.color = options.color || { options: 'red' };


		// setup an in and out port
		this.addPort(
			new OFCPortModel({
				in: true,
				name: 'in'
			})
		);
		this.addPort(
			new OFCPortModel({
				in: false,
				name: 'out'
			})
		);
	}

	getPrev = () => {
		return this.options.previous_node;
	};

    getNext = () => {
        return this.options.next_node;
    };

	setOrdre = (newOrdre) => {
		this.options.ordre = newOrdre;
	};

	setAfficheParam = (fct) =>{
		this.options.fctparam = fct;
	};

	setExecute = (fct) => {
		this.options.fctexecute = fct;
	};

    setRemoveModel = (fct) =>{
        this.options.removeModel = fct;
    };

    setUpdateName = (fct) =>{
        this.options.updateName = fct;
    };

	setUpdateOrdre = (fct) =>{
        this.options.updateOrdre = fct;
	};

	setfctgetLastOrdre = (fct) => {
		this.options.getLastOrdre = fct;
	};
	
	setfctgetLastIdNode = (fct) => {
		this.options.getLastIdNode = fct;
	};
	
	serialize() {
		return {
			...super.serialize(),
			color: this.options.color,
            typeTU: this.options.typeTU,
            key: this.options.key,
			name: this.options.name,			
			id_node: this.options.id_node,
			ordre: this.options.ordre,
			id_boucle: this.options.id_boucle,
			params : this.options.params,
			schema : this.options.schema,
            previous_node: this.options.previous_node,
            next_node: this.options.next_node,
		};
	}

	deserialize(ob, engine) {
		super.deserialize(ob, engine);
		this.options.color = ob.data.color;
        this.options.typeTU = ob.data.typeTU;
        this.options.key = ob.data.key;
		this.options.name = ob.data.name;		
		this.options.id_node = ob.data.id_node;
		this.options.ordre = ob.data.ordre;
		this.options.id_boucle = ob.data.id_boucle;
		this.options.params = ob.data.params; 
		this.options.schema = ob.data.schema;
        this.options.previous_node = ob.data.previous_node;
		this.options.next_node = ob.data.next_node;
	}
}
