import React from 'react';
import * as Yup from 'yup';
import {Checkboxes,Select, makeRequired} from 'mui-rff';
import CustomTextField from '../CustomTextField';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuCegidDataServiceschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const required = makeRequired(TuCegidDataServiceschema);


function TuCegidDataService (props) {
    const {t} = useTranslation('diagram');

    const helperText = t("param.requis");

    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let HttpConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={HttpConnexions}
                />
            </Grid>;
    }

    return (
        <React.Fragment>

            {connexionField}


            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.dossier")}
                    name="dossier"
                    id="dossier"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.tablename")}
                    name="tablename"
                    id="tablename"
                />
            </Grid>            
            <Grid item xs={12} key={7}>
                <CustomTextField
                    label={t("param.filter")}
                    name="filter"
                    id="filter"
                />
            </Grid>
            <Grid item xs={12} key={8}>
                <CustomTextField
                    label={t("param.orderby")}
                    name="orderby"
                    id="orderby"
                />
            </Grid>
            <Grid item xs={12} key={9}>
                <CustomTextField
                    label={t("param.fieldselect")}
                    name="fieldselect"
                    id="fieldselect"
                />
            </Grid>
            <Grid item xs={12} key={10}>
                <CustomTextField
                    label={t("param.top")}
                    name="top"
                    id="top"
                />
            </Grid>
            <Grid item xs={12} key={11}>
                <CustomTextField
                    label={t("param.skip")}
                    name="skip"
                    id="skip"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <Checkboxes
                    name="okfulldatas"
                    formControlProps={{ margin: 'none' }}
                    data={{ label: t("param.okfulldatas"), value: false }}
                />
            </Grid>

        </React.Fragment>
    );
}

export default TuCegidDataService;
