import React, { useContext } from 'react';
import './Parametres.css';
//import Form from '@rjsf/material-ui';
import FormOFC from '../UI/Forms/FormOFC';
import StructureDefinePopUp from './Structure/StructureDefinePopUp';
import { ConnexionsContext } from '../../context/ConnexionsContext';

import TuJson, {TuJSONschema} from './Form/TraitementUnitaire/TuJSON';
import TuVariable, {TuVariableschema} from './Form/TraitementUnitaire/TuVariable';
import TuCreateData, {TuCreateDataschema} from './Form/TraitementUnitaire/TuCreateData';
import TuCSV, {TuCSVschema} from './Form/TraitementUnitaire/TuCSV';
import TuForeach, {TuForeachschema} from './Form/TraitementUnitaire/TuForeach';
//import * as tuHTTP from './Form/TraitementUnitaire/TuHTTP';
import TuHTTP, {TuHTTPschema} from './Form/TraitementUnitaire/TuHTTP';
import TuShopify, {TuShopifyschema} from './Form/TraitementUnitaire/TuShopify';
import TuText, {TuTextschema} from './Form/TraitementUnitaire/TuText';
import TuXML, {TuXMLschema} from './Form/TraitementUnitaire/TuXML';
import TuArray, {TuArrayschema} from './Form/TraitementUnitaire/TuArray';
import TuXrpFlex, {TuXrpFlexschema} from './Form/TraitementUnitaire/TuXrpFlex';
import TuXrpFlexputcde, {TuXrpFlexputcdeschema} from './Form/TraitementUnitaire/TuXrpFlexputcde';
import TuCegidDataService, {TuCegidDataServiceschema} from './Form/TraitementUnitaire/TuCegidDataService';
import TuFTP, {TuFTPschema} from './Form/TraitementUnitaire/TuFTP';
import TuMySQL, {TuMySQLschema} from './Form/TraitementUnitaire/TuMySQL';
import TuHubspot, {TuHubspotschema} from './Form/TraitementUnitaire/Hubspot/TuHubspot';
import TuHubspotCompanies, {TuHubspotschemaCompanies} from './Form/TraitementUnitaire/Hubspot/TuHubspotCompanies';

import { useTranslation } from 'react-i18next';

function Parametres (props) {
    let schema = {};
    const { t } = useTranslation('diagram');
    let formFields = [];
    const { connexionsList } = useContext(ConnexionsContext);
    // console.log(props);
    switch (props.paramType) {
        case 'parsejson':
        case 'createjson':
            schema = TuJSONschema;
            formFields = <TuJson/>;
            break;
        case 'setvariable':
        case 'getvariable':
            schema = TuVariableschema;
            formFields = <TuVariable />;
            break;
        case 'httpsimple':
        case 'oauthhttp':
            schema = TuHTTPschema;
            formFields = <TuHTTP connexionData={connexionsList['http']} connexionDataSmtp={connexionsList['smtp']} />;
            break;
        case 'createdata':
            schema = TuCreateDataschema;
            formFields = <TuCreateData/>;
            break;
        case 'parsexml':
        case 'createxml':
            schema = TuXMLschema;
            formFields = <TuXML/>;
            break;
        case 'createtxt':
            schema = TuTextschema;
            formFields = <TuText/>;
            break;
        case 'foreach':
            schema = TuForeachschema;
            formFields = <TuForeach/>;
            break;
        case 'array':
            schema = TuArrayschema;
            formFields = <TuArray />;
            break;
        case 'parsecsv':
            schema = TuCSVschema;
            formFields = <TuCSV/>;
            break;
        case 'articleshopify':
            schema = TuShopifyschema;
            formFields = <TuShopify/>;
            break;
        case 'ftp':
            schema = TuFTPschema;
            formFields = <TuFTP connexionData={connexionsList['ftp']}/>;
            break;
        case 'mysql':
            schema = TuMySQLschema;
            formFields = <TuMySQL connexionData={connexionsList['mysql']}/>;
            break;
        case 'email':
            break;
        case 'entityxrpflex':
            schema = TuXrpFlexschema;
            formFields = <TuXrpFlex connexionData={connexionsList['http']}/>;
            break;
        case 'xrpflexputcde':
            schema = TuXrpFlexputcdeschema;
            formFields = <TuXrpFlexputcde connexionData={connexionsList['http']}/>;
            break;
        case 'cegiddataservice':
            schema = TuCegidDataServiceschema;
            formFields = <TuCegidDataService connexionData={connexionsList['http']}/>;
            break;
        case 'hubspot':
            schema = TuHubspotschema;
            formFields = <TuHubspot connexionData={connexionsList['http']}/>;
            break;
        case 'hubspotcompanies':
            schema = TuHubspotschemaCompanies;
            formFields = <TuHubspotCompanies connexionData={connexionsList['http']}/>;
            break;
        default:
            break;
    }

    console.log (formFields);

    return (
        <div className={"w3-sidebar w3-bar-block w3-card parameter-sidebar"}>
            <div className={"w3-bar-item param-header " + props.paramType}>
                <div className="nav__close" onClick={props.afficheParam} />
                <h6 className={"tu-param-title"}><span className="material-icons tu-param-icon">settings</span> {t("param.titre")}</h6>
                <p className={"tu-param-name"}>{props.paramName ? props.paramName : 'Menu'}</p>
            </div>
            <StructureDefinePopUp id_node={props.idnode}
                name={props.paramName} />
            <FormOFC initialValues={props.params}
                schema={schema}
                formFields={formFields}
                 closePopup={props.afficheParam}
                onSubmit={(formData, e) => props.sauveparam(props.keynode, formData)}
            />
            {/* <Form schema={props.schema}
                  formData={props.params}
                  onSubmit={({formData}, e) => props.sauveparam(props.keynode,formData)}/> */}

        </div>
    );
};

export default Parametres;