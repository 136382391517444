import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../UI/Modal/Modal';
import { withFirebase } from '../../Firebase';
import * as ROLES from '../../../config/roles';
import { Grid } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormOFC from '../../UI/Forms/FormOFC';
import * as Yup from 'yup';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';
import { withTranslation } from 'react-i18next';


// TODO : implement this class
const INITIAL_STATE = {
    clientId: '',
    id: '',
    name: '',
    error: null,
};
const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class FolderParam extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount () {
        this.setState({...this.props.folderParamInfos});
    }

    onSubmit = event => {
        const { id, name } = this.state;
        const clientId = this.props.clientId;

        if (id) {
            this.props.firebase
                .folder(clientId, id)
                .update({
                    name
                })
                .then(() => {
                    this.props.onSubmit();
                })
                .catch(error => {
                    this.setState({ error });
                });

        } else {
            this.createFolder(name, clientId);
        }
        //event.preventDefault();
    };


    createFolder = (folderName, clientId) => {
        //get new id
        const newFolderId = this.props.firebase.folders(clientId).push().key;
        this.props.firebase.folder(clientId, newFolderId).set({
            name:folderName
        }).then(() => {
            this.props.onSubmit();
        });
        //this.updateFolderList();
    };


    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            name,
            error
        } = this.state;

        const { t } = this.props;

        const helperText = t("form.requis");

        const isInvalid =
            name === '';


        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t("form.nomRepertoire")}
                    name="name"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={name}
                />
            </Grid>
        </React.Fragment>;


        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label={t("form.nomRepertoire")}
                        name="name"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={name}
                    />)
            }
        ];*/

        return (

            <React.Fragment>
                <h1>{t("form.editRepertoire")}</h1>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
        );
    }
}


const CompleteFolderParam = withRouter(withFirebase(withTranslation()(FolderParam)));

export default CompleteFolderParam;