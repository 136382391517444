import React from 'react';
import {DefaultLinkFactory} from '@projectstorm/react-diagrams';
import OFCLinkModel from './OFCLinkModel';
import { AdvancedLinkSegment } from './AdvancedLinkSegment';
import { AdvancedLinkWidget } from './AdvancedLinkWidget';

export class OFCLinkFactory extends DefaultLinkFactory {
	constructor() {
		super('ofclink'); // <-- this matches with the link model above
	}

	generateModel(event) {
		const model = new OFCLinkModel(); // <-- this is how we get new instances
		/*console.log(event);
        model.registerListener({
            sourcePortChanged: e => console.log("sourcePortChanged", e),
            targetPortChanged: e => console.log("targetPortChanged", e),
            selectionChanged: e => console.log("selectionChanged", e),
            entityRemoved: e => console.log("entityRemoved", e),
        });*/

        return model;
	}

    updateOrdre(Link) {
		if(Link.getTargetPort()) {
            const sourceOrdre = Link.getSourcePort().getNode().options.ordre;
            const targetOrdre = Link.getTargetPort().getNode().options.ordre;

            if (sourceOrdre >= targetOrdre) {
            	const prevNode =  Link.getSourcePort().getNode();
                const nextNode =  Link.getTargetPort().getNode();
            	const idNextNode = nextNode.options.id;
            	prevNode.options.next_node = idNextNode;
            	nextNode.options.previous_node = prevNode.options.id;
            	//console.log (Link.getTargetPort().getNode().options);
                Link.getTargetPort().getNode().options.updateOrdre(idNextNode, sourceOrdre + 1);
            }
		}
    }

    generateReactWidget(event) {

		this.updateOrdre(event.model);

		return <AdvancedLinkWidget link={event.model} diagramEngine={this.engine} />;
	}
    /**
     * @override the DefaultLinkWidget makes use of this, and it normally renders that
     * familiar gray line, so in this case we simply make it return a new advanced segment.
     */
	// generateLinkSegment(model, selected, path) {        
    //     return (
	// 		<g>
	// 			<AdvancedLinkSegment model={model} path={path} engine={this.engine}/>
	// 		</g>
	// 	);
	// }
}