import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../UI/Modal/Modal';
import { withFirebase } from '../../Firebase';
import AuthUserContext from '../../Session/Session';
import * as ROLES from '../../../config/roles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { Grid } from '@material-ui/core';
import FormOFC from '../../UI/Forms/FormOFC';
import * as Yup from 'yup';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';

import FormControl from '@material-ui/core/FormControl';
import systems from "../../../assets/node-icons/systems/";
import axios from "../../../axios-base";

import { withTranslation } from 'react-i18next';

// TODO : implement this class
const INITIAL_STATE = {
    id: null,
    user: '',
    name: '',
    error: null,
};
const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class ClientParams extends Component {

    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount () {
        this.setState({...this.props.clientParamsInfos});
        console.log(this.context);

    }

    onSubmit = event => {
        const { id, name, guid } = this.state;
        if(!id) {
            this.createClient();
        }
        if(id) {
            this.props.firebase
                .client(id)
                .update({
                    name
                })
                .then(() => {
                    this.props.onSubmit();
                })
                .catch(error => {
                    this.setState({ error });
                });
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    createClient = () => {
        const { name } = this.state;
        const user = this.context.uid;

        axios.post('/createCustomer/', {name:name, user:user})
            .then(response => {
                if(response.data)
                    this.props.updateClients(response.data);
                this.props.onSubmit();
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    render() {
        const {
            name,
            error
        } = this.state;
        const {t} = this.props;

        const helperText = t('form.requis');

        const isInvalid =
            name === '';

        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t("form.nom")}
                    name="name"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={name}
                />
            </Grid>
        </React.Fragment>;

        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label={t("form.nom")}
                        name="name"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={name}
                    />)
            }
        ];*/

        const actionTitle = (this.state.id) ? t("client.edit") : t("client.ajout");


        return (
            <React.Fragment>
                <h1>{actionTitle}</h1>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
        );
    }
}


const CompleteClientParams = withRouter(withFirebase(withTranslation()(ClientParams)));

export default CompleteClientParams;