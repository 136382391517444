import React, { Component, useContext } from 'react';
import { StructureContext, withStructure } from './StructureContext';
import Popup from "reactjs-popup";
import { Button } from '@material-ui/core';
import axios from '../../../axios-base';
import Form from '@rjsf/material-ui';

const schemaStructure = {
    "title": "Ajout structure données",
    "type": "object",
    "properties": {
        "datatype": {
            "type": "string",
            "title": "Type de données",
            "enum": [
                "JSON",
                "XML",
                "CSV"
            ]
        },
        "data": {
            "type": "string",
            "title": "exemple de données"
        }
    }
};
const uiSchemaStructure = {
    "data": {
        "ui:widget": "textarea"
    }
};

const StructureDefinePopUp = (props) => {
    // export default withStructure(({structureList,setStructureList})(props) => (
    const { structureList, setStructureList } =  useContext(StructureContext);
    return (
        // <StructureContext.Consumer>
        //      {({ structureList, setStructureList }) => (

                <Popup position="right top"
                    trigger={<Button variant="contained" >definir la structure</Button>}
                    contentStyle={{ width: 'auto' }}>
                    {close => (<div>
                        <div>
                            <Form
                                uiSchema={uiSchemaStructure}
                                schema={schemaStructure}
                                onSubmit={({ formData }, e) => {
                                    // console.log(structureList);
                                    axios.post('/getStructure', formData)
                                        .then((response) => {                                            
                                            let tempstructure = {...structureList};
                                            //if (Array.isArray(structureList)) tempstructure = [...structureList];
                                            // console.log(tempstructure);
                                            let data = new Object;
                                            data[props.id_node] =response.data;
                                            let newstruct = new Object;
                                            newstruct['id_node']=props.id_node;
                                            newstruct['name']=props.name;
                                            newstruct['data'] = data;
                                            tempstructure['¤'+props.id_node]=newstruct;
                                            // console.log(props.id_node);console.log(response.data); console.log(tempstructure); 
                                            setStructureList(tempstructure); close(); })
                                        .catch((error) => { console.log(error) });
                                }}
                            >
                                <div><Button variant="contained" onClick={close}>Cancel</Button>
                                    <Button variant="contained" color="primary" type="submit">Save</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    )}
                </Popup>
            //  )}
        //  </StructureContext.Consumer>
    // ));
    )}

 export default StructureDefinePopUp;