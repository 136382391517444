import React from 'react';

import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

function Search (props) {
    const { t } = useTranslation();

    return (
        <form className={"mb-4"} noValidate autoComplete="off">
            <TextField id="outlined-basic" label={t("form.rechercher")} variant="outlined" value={props.value} onChange={props.onChange}/>
        </form>
    );
};

export default Search;