import * as React from 'react';

import { EditableLabelModel } from './EditableLabelModel';
import styled from '@emotion/styled';
import Hide from '../../UI/Hide/Hide';
// import { action } from '@storybook/addon-actions';

export interface FlowAliasLabelWidgetProps {
	model: EditableLabelModel;
}

export const Label = styled.div`
	user-select: none;
	pointer-events: auto;
`;


// now we can render all what we want in the label
export const EditableLabelWidget: React.FunctionComponent<FlowAliasLabelWidgetProps> = (props) => {
	const [str, setStr] = React.useState(props.model.value);
	const link = props.model.getParent();

	return (
		<Hide show={link.getOptions().selected}>
			<Label>
				<button className={"btn btn-danger rounded-circle"}
					onClick={() => {props.model.getParent().remove(); }}
				>
                    <i className="material-icons">delete_forever</i>
				</button>
			</Label>
		</Hide>
	);
}; 