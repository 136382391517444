import React from 'react';
import Chip from '../../Chip/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

function Scenario(props) {
    const { t } = useTranslation();
    let displayValue = 'block';

    if(props.folderFilter !== 'all' && props.folderFilter !== props.folder) {
        displayValue = 'none';
    }

    return (
        <div  className="btn list-group-item list-group-item-action input-group-prepend" style={{display: displayValue}}>
            <div className={"scenario-item"}>
                <div className={"scenario-item-cartouche"}>
                    <Chip start={props.chipStart} end={props.chipEnd} />
                    <p className={"scenario-item-cartouche-name"} onClick={props.clicked}>{props.name}</p>
                </div>
                <div className="btn-group btn-group-md">

                    <button type="button" className="btn btn-success" onClick={props.playFlux}>
                        <Tooltip title={t("scenarios.execute")}><i className="material-icons">play_arrow</i></Tooltip>
                    </button>

                    <button type="button" className="btn btn-default" onClick={props.copyLink}>
                        <Tooltip title={t("scenarios.copy")}><i className="material-icons">link</i></Tooltip>
                    </button>


                    <button type="button" className="btn btn-primary" onClick={props.editParams}>
                        <Tooltip title={t("scenarios.parametres")}><i className="material-icons">settings</i></Tooltip>
                    </button>


                    <button type="button" className="btn btn-danger" onClick={props.deleteFlux}>
                        <Tooltip title={t("scenarios.supprimer")}><i className="material-icons">delete_forever</i></Tooltip>
                    </button>

                </div>

            </div>
        </div>
    );
};

export default Scenario;