import React from 'react';
import * as Yup from 'yup';
import { makeRequired, Select, Checkboxes } from 'mui-rff';
import CustomTextField from '../CustomTextField';
import CustomFieldArray from '../CustomFieldArray';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuMySQLschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const required = makeRequired(TuMySQLschema);

function TuMySQL (props) {
    const {t} = useTranslation('diagram');

    const helperText = t("param.requis");

    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let MySQLConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={MySQLConnexions}
                />
            </Grid>;
    }

    return (
        <React.Fragment>

            {connexionField}

            <Grid item xs={12} key={1}>
                <Select
                    label={t("param.type")}
                    name="type"
                    data={[
                        { label: t("param.request"), value: 'sql' },
                        { label: 'Insert', value: 'insert' },
                        { label: 'Update', value: 'update' },
                        { label: 'Delete', value: 'delete' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.tablename")}
                    name="tablename"
                    id="tablename"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.sqlstring")}
                    name="sqlstring"
                    id="sqlstring"
                />
            </Grid>
            <Grid item xs={12} key={4}>
                <CustomTextField
                    label={t("param.datas")}
                    name="datas"
                    id="datas"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <Checkboxes
                    // label="is data a file"
                    name="oktruncate"
                    // required={required.best}
                    data={{ label: t("param.oktruncate"), value: true }}
                    helperText={helperText}
                />
            </Grid>
            <Grid item xs={12} key={6}>
                <CustomFieldArray
                    label={t("param.columnvalues")}
                    name="columnvalues"
                    addlabel={t("param.addcolumn")}
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.wherecondition")}
                    name="wherecondition"
                    id="wherecondition"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.ordercondition")}
                    name="ordercondition"
                    id="ordercondition"
                />
            </Grid>

        </React.Fragment>
    );
}

export default TuMySQL;
