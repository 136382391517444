import React, { createContext, Component } from 'react';
import typesTranslated from "../../Diagram/CatalogTypesTranslated";
// export const StructureContext = React.createContext();
export const StructureContext = React.createContext({
    structureList: {},
    setStructureList: () => { }
});

class StructureProvider extends Component {
    state = {
        structureList: {}, // une valeur de départ
        setStructureList: structureList => this.setState({ structureList: structureList })
    };

    render() {
        return (
            /**
             * la propriété value est très importante ici, elle rend ici
             * le contenu du state disponible aux `Consumers` de l'application
             */
            <StructureContext.Provider value={this.state}>
                {this.props.children}
            </StructureContext.Provider>
        );
    }
}

export const withStructure = Component => props => (
    <StructureContext.Consumer>
      {store => <Component {...props} {...store} />}
    </StructureContext.Consumer>
  );
    
export default StructureProvider;

