export default {
    cegid: require('./cegid.png'),
    ebp: require('./ebp2.png'),
    magento: require('./magento.png'),
    prestashop: require('./prestashop.png'),
    sage: require('./sage.png'),
    shopify: require('./shopify.png'),
    woocommerce: require('./wooCommerce2.png'),
    system: require('./system.png'),
    hubspot: require('./hubspot.png'),
    exact: require('./exact.png'),
    epages: require('./epages.png')
};


