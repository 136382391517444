import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import systems from "../../assets/node-icons/systems";
import {useTranslation} from "react-i18next";


const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        zIndex:100,
        width: drawerWidth,
        flexShrink: 0,
        left: 160,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    list:{marginLeft:60}
}));

export default function PermanentDrawerLeft(props) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const listFolders = props.folderList.map(itKey => {
        return <ListItem button key={itKey.id} onClick={() => props.openFolder(itKey.id)}>
            <ListItemIcon><i className="material-icons">folder</i></ListItemIcon>
            <ListItemText primary={itKey.name} />
            <ListItemIcon><i className="material-icons" onClick={() => props.editFolder(itKey)}>settings</i></ListItemIcon>
        </ListItem>;

    });

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={"h4 mt-4 pl-4 " +classes.list} ><p>BUISINE</p></div>
                <Divider />
                <List className={classes.list} >


                    <ListItem button key={"all_flux"} onClick={() => props.openFolder('all')}>
                        <ListItemIcon><i className="material-icons">folder_open</i></ListItemIcon>
                        <ListItemText primary={t("scenarios.alltheflux")} />
                    </ListItem>

                    {listFolders}
                </List>
                <Divider />
                <List className={classes.list}>
                    <ListItem button key={"add_folder"} onClick={() => props.editFolder(null)}>
                        <ListItemIcon><i className="material-icons">create_new_folder</i></ListItemIcon>
                        <ListItemText primary={t("scenarios.ajoutRep")} />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}
