import React, { createContext, useState} from 'react';

export const ConnexionsContext = createContext({
    connexionsList: null,
    setConnexionsList: () => { }
});

const ConnexionsProvider = props => {
    const [connexionsList, setConnexionsList] = useState({});
    // state = {
    //     structureList: {}, // une valeur de départ
    //     setStructureList: structureList => this.setState({ structureList: structureList })
    // };    
        return (
            /**
             * la propriété value est très importante ici, elle rend ici
             * le contenu du state disponible aux `Consumers` de l'application
             */
            <ConnexionsContext.Provider value={{connexionsList, setConnexionsList}}>
                {props.children}
            </ConnexionsContext.Provider>
        );    
}
    
export default ConnexionsProvider;

