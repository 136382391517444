import {DefaultPortModel} from '@projectstorm/react-diagrams';
import OFCLinkModel from './OFCLinkModel';

export class OFCPortModel extends DefaultPortModel {

	constructor(options = {}) {
		super({
			...options,
			type: 'ofcport'
		});
	}

    canLinkToPort = (targetPort) => {
        const links = Object.values(this.getLinks() || {});
        return (
            this.getParent().getID() !== targetPort.getParent().getID() &&
            //links.length === 1 &&
            links.length <= 4 &&
            targetPort.getName() === "in"
        );
    };

	createLinkModel() {
		return new OFCLinkModel();
	}
}