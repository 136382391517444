import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../UI/Modal/Modal';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../config/routes';
import * as ROLES from '../../config/roles';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const SignUpPage = () => (
    <div>
        <h1>Enregistrement</h1>
        <SignUpForm />
    </div>
);

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    roles : [],
    error: null,
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { username, email, passwordOne, roles } = this.state;

        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username,
                        email,
                        roles,
                    })
                    .then(() => {
                        this.setState({ ...INITIAL_STATE });
                        this.props.history.push(ROUTES.HOME);
                    })
                    .catch(error => {
                        this.setState({ error });
                    });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeSelect = event => {
        let roles = [];
        roles[event.target.value] = event.target.value;
        this.setState({ roles: roles });
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            roles,
            error
        } = this.state;

        const { t } = this.props;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        const roleValue = roles ? roles.map(role => {return role}) : ROLES.CLIENT ;

        return (
            <Modal show={true}>
            <form onSubmit={this.onSubmit}>
                <input
                    name="username"
                    value={username}
                    onChange={this.onChange}
                    type="text"
                    placeholder={t("admComptes.username")}
                />
                <input
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder={t("admComptes.email")}
                />
                <input
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder={t("admComptes.password")}
                />
                <input
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder={t("admComptes.confirmPass")}
                />
                <FormControl>
                    <InputLabel id="role-simple-select-label">{t("admComptes.role")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name={'roles'}
                        value={roleValue}
                        onChange={this.onChangeSelect}
                    >
                    <MenuItem value={ROLES.CLIENT}>{ROLES.CLIENT}</MenuItem>
                        <MenuItem value={ROLES.USER}>{ROLES.USER}</MenuItem>
                        <MenuItem value={ROLES.ADMIN}>{ROLES.ADMIN}</MenuItem>

                    </Select>)
                </FormControl>
                <button disabled={isInvalid} type="submit">
                    {t("admComptes.signUp")}
                </button>

                {error && <p>{error.message}</p>}
            </form>
            </Modal>
        );
    }
}

function SignUpLink () {
    const { t, i18n } = useTranslation();
    return <p>
        <Link to={ROUTES.SIGN_UP}>{t("admComptes.ajout")}</Link>
    </p>
};

const SignUpForm = withRouter(withFirebase(withTranslation()(SignUpFormBase)));

export default SignUpPage;

export { SignUpForm, SignUpLink };