import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../Firebase';

import FormOFC from '../../UI/Forms/FormOFC';
import {TextField} from 'mui-rff';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class ToolboxExport extends Component {
    render() {
        const {t} = this.props;
        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t('toolbox.jsondatas')}
                    name="jsondatas"
                    multiline={true}
                    rows= {15}
                />
            </Grid>
        </React.Fragment>;
        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label="Json du flux"
                        name="jsondatas"
                        multiline={true}
                        rows= {15}
                    />)
            }

        ];*/
        const jsondatas = this.props.exportScenario();        
        return (

            <React.Fragment>
                <h1>Import / Export</h1>
                <hr className="my-4"/>
                <FormOFC initialValues={{'jsondatas':jsondatas}}
                         closePopup={this.props.closePopup}
                         formFields={formFields}
                />
            </React.Fragment>
        );
    }
}


const CompleteToolboxParamsExport = withRouter(withFirebase(withTranslation('diagram')(ToolboxExport)));

export default CompleteToolboxParamsExport;