import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import config from '../../config/firebase';

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.database();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .once('value')
                    .then(snapshot => {
                        const dbUser = snapshot.val();
                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            ...dbUser,
                        };

                        next(authUser);
                    });
            } else {
                fallback();
            }
        });

    // *** User API ***

    user = uid => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');


    client = uid => this.db.ref(`clients/${uid}`);

    clients = () => this.db.ref('clients');


    scenario = (clientId, uid) => this.db.ref(`clients/${clientId}/scenarios/${uid}`);

    diagram = (clientId, uid) => this.db.ref(`clients/${clientId}/scenarios/${uid}/diagram`);

    structure = (clientId, uid) => this.db.ref(`clients/${clientId}/scenarios/${uid}/structure`);

    scenarios = (clientId) => this.db.ref(`clients/${clientId}/scenarios`);


    folder = (clientId, uid) => this.db.ref(`clients/${clientId}/folders/${uid}`);

    folders = (clientId) => this.db.ref(`clients/${clientId}/folders`);
}

export default Firebase;