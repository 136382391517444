import axios from 'axios';

const instance = axios.create({
   // url demo
    // baseURL: 'https://openfluxcloud.openfluxdemo.com/',
   //url dev 
    //  baseURL: 'https://back.openfluxdev.com/',
   //url prod
    baseURL: 'https://back.openfluxcloud.com/',
    
    //headers:  {'Access-Control-Allow-Origin': '*'}
});

export default instance;