import React, {Component} from 'react';

import FilterResults from 'react-filter-search';
import Search from '../../Search/Search';
import data from "../../../assets/node-icons";
import './SelectFilter.css';
import { withTranslation } from 'react-i18next';


class SelectFilter extends Component {

    state = {
        data:null,
        value:''
    };

    componentDidMount () {
        this.setState({data: this.props.data});
    }

    handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };

    onClickMethod = (NodeId) => {

        this.props.createNode(NodeId);

        if(this.props.closePopup) {
            this.props.closePopup();
        }

    };

    render () {
        const { value } = this.state;
        const {t} = this.props;
        let formatedItems = null;

        if(this.state.data) {
            formatedItems = <FilterResults
                value={value}
                data={this.state.data}
                renderResults={results => (
                    results.map(itKey => {
                        const tuImg = data[itKey.code];
                        return <div className="tu-item col-12" key={itKey.id} onClick={() => this.onClickMethod(itKey.id)}>{t(itKey.name)} <img className={"tuIcon"} src={tuImg} /></div>

                    })
                        //{itKey}
                )}
            />;
        }

        return (
            <React.Fragment>
                <div className={"searchFilter"}>
                    <Search value={this.state.value} onChange={this.handleChange}/>
                </div>
                    {formatedItems}
            </React.Fragment>
        );
    }
}

export default withTranslation('diagram')(SelectFilter);