import React from 'react';
import './Hide.css';

const hide = ( props) => {
    const displayValue = props.show ?  "inherit" : "none";

    return (
        <div style={{display:displayValue}}>{props.children}</div>);
        //<div className={"hideElement"}>{props.children}</div>);
    };

export default hide;