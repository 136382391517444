export default {
    setvariable: require('./put.png'),
    getvariable: require('./get.png'),
    createdata: require('./createdata.png'),
    createjson: require('./createjson.png'),
    parsejson: require('./parsejson.png'),
    parsexml: require('./parsexml.png'),
    createxml: require('./createxml.png'),
    createtxt: require('./generatetxt.png'),
    parsecsv: require('./createcsv.png'),
    foreach: require('./iteration.png'),
    mysql: require('./mysql.png'),
    email: require('./mail.png'),
    array: require('./addtoarray.png'),
    cegid: require('./cegid.png'),
    ebp: require('./ebp2.png'),
    oauthhttp: require('./https.png'),
    ftp: require('./ftp.png'),
    sftp: require('./ftp.png'),
    httpsimple: require('./http.png'),
    logicielfinancier: require('./logicielfinancier.png'),
    API: require('./API.png'),
    baseinformations: require('./baseinformations.png'),
    chatbot: require('./chatbot.png'),
    ERP: require('./ERP.png'),
    IOT: require('./IOT.png'),
    edoc: require('./edoc.png'),
    basesql: require('./basesql.png'),
    ecommerce: require('./ecommerce.png'),
    articleshopify: require('./shopify.png'),
    entityxrpflex: require('./xrpflex.png'),
    hubspot: require('./hubspot.png'),
    exact: require('./exact.png'),
    cegiddataservice : require('./cegiddataservice.png')
};


