import React, { createContext, useState} from 'react';

export const PopupJsonContext = createContext({
    // openPopup: false,
    // setOpenPopup: () => { },
    path: null,
    setPath: () => { },
});

const PopupJsonProvider = props => {
    // const [openPopup, setOpenPopup] = useState({});
    const [path, setPath] = useState({});
    // state = {
    //     structureList: {}, // une valeur de départ
    //     setStructureList: structureList => this.setState({ structureList: structureList })
    // };    
        return (
            /**
             * la propriété value est très importante ici, elle rend ici
             * le contenu du state disponible aux `Consumers` de l'application
             */
            <PopupJsonContext.Provider value={{path,setPath}}>
                {props.children}
            </PopupJsonContext.Provider>
        );    
}
    
export default PopupJsonProvider;

