import React from 'react';
import * as Yup from 'yup';
import {TextField, makeRequired} from 'mui-rff';
import CustomTextField from '../CustomTextField';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuXMLschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const helperText = '* Requis';
const required = makeRequired(TuXMLschema);

function TuXML () {

    const {t} = useTranslation('diagram');

    return (
        <React.Fragment>

            <Grid item xs={12} key={1}>
                <CustomTextField
                    label={t("param.datas")}
                    name="datas"
                    id="datas"
                    required={required.datas}
                    // helperText={helperText}
                />
            </Grid>


            <Grid item xs={12} key={2}>
                <TextField
                    label={t("param.root_name")}
                    name="root_name"
                />
            </Grid>

        </React.Fragment>
    );
}

export default TuXML;