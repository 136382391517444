import React, {Component, createRef} from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
// import { LabelModel } from './LabelModel';
import styled from '@emotion/styled';


	export const Label = styled.div`
		display: inline-block;
		position: absolute;
	`;

	// export const Foreign = styled.foreignObject`
	// 	pointer-events: none;
	// 	overflow: visible;
	// `;


export default class CartoucheLinkWidget extends Component {	
	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	componentDidUpdate() {
		window.requestAnimationFrame(this.calculateLabelPosition);
	}

	componentDidMount() {
		window.requestAnimationFrame(this.calculateLabelPosition);
	}

	findPathAndRelativePositionToRenderLabel = (index) => {
		// an array to hold all path lengths, making sure we hit the DOM only once to fetch this information
        const link = this.props.link;//this.props.label.getParent();
        // console.log(link.getRenderedPath()[0].childNodes[0].getTotalLength());
        // link.getRenderedPath().map((path) => console.log(path));
		// const lengths = link.getRenderedPath().map((path) => path.getTotalLength());
        const lengths = link.getRenderedPath()[0].childNodes[0].getTotalLength();
		// calculate the point where we want to display the label
		let labelPosition = lengths/2;
			//lengths.reduce((previousValue, currentValue) => previousValue + currentValue, 0) *
			// (index / 2);

		// find the path where the label will be rendered and calculate the relative position
		let pathIndex = 0;
		// while (pathIndex < link.getRenderedPath().length) {
		// 	if (labelPosition - lengths[pathIndex] < 0) {
				return {
					path: link.getRenderedPath()[0].childNodes[0],
					position: labelPosition
				};
		// 	}

		// 	// keep searching
		// 	labelPosition -= lengths[pathIndex];
		// 	pathIndex++;
		// }
	};

	calculateLabelPosition = () => {
		const found = this.findPathAndRelativePositionToRenderLabel(this.props.index + 1);
		if (!found) {
			return;
		}

		const { path, position } = found;

		const labelDimensions = {
			width: this.ref.current.offsetWidth,
			height: this.ref.current.offsetHeight
		};

		const pathCentre = path.getPointAtLength(position);

		const labelCoordinates = {
			x: pathCentre.x - labelDimensions.width / 2 , //+ this.props.label.getOptions().offsetX
			y: pathCentre.y - labelDimensions.height / 2 + 10 //+ this.props.label.getOptions().offsetY
		};

		this.ref.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`;
	};

	render() {
		const canvas = this.props.engine.getCanvas();
		return (
            
			// <foreignObject key={1} width={canvas.offsetWidth} height={canvas.offsetHeight}>
			// 	<Label ref={this.ref}>
			//  		{/* {this.props.engine.getFactoryForLabel(this.props.label).generateReactWidget({ model: this.props.label })} */}
            //          <button onClick={this.props.link.remove}>X</button>
			//  	</Label>
            //  </foreignObject>
            <rect ref={this.ref} class="btn" x="0" y="0" width="10" height="10" onClick="alert('click!')" />
		);
	}
}
