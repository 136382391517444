import React, {PureComponent} from 'react';
import axios from '../../axios-base';
import Scenario from './Scenario/Scenario';
import ScenarioParams from './ScenarioParams/ScenarioParams';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import Drawer from '../Drawer/Drawer';
import FolderParam from '../Drawer/FolderParam/FolderParam';
import './Scenarios.css';
import withAuthorization from "../Session/withAuthorization/withAuthorization";
import * as ROUTES from "../../config/routes";
import { useParams } from "react-router-dom";
import * as ROLES from "../../config/roles";

import { withTranslation } from 'react-i18next';


class Scenarios extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            client:{id:null,name:null},
            folderFilter: 'all',
            listScenarios: [],
            scenarioDatas:null,
            scenarios: false,
            scenarioParams: null,
            loading: true,
            loadingScenario: false,
            editing: false,
            folderEditing: false,
            folderParam: null,
            folderList: []
        };
    }



    componentDidMount() {

        const clientId = (this.state.client.id ? this.state.client.id : this.props.client.id);
        if (!clientId)
            this.props.history.push(ROUTES.SIGN_IN);

        if (this.props.client) {
            this.setState({client:this.props.client});
        }
        this.updateFolderList(clientId);
        this.refreshScenarioList();

    }

    /**
     * FOLDER RELATED FUNCTIONS
     */
    updateFolderList = (clientId = null) => {

        if(clientId === null)
            clientId = this.state.client.id;
        this.props.firebase.folders(clientId).on('value', snapshot => {
            let foldersObject = snapshot.val();

            if (foldersObject) {
                const foldersList = Object.keys(foldersObject).map(key => ({
                    ...foldersObject[key],
                    id: key,
                }));
                this.setState({folderList:foldersList});
            }
        });
    };

    editFolder = (folderParam) => {
        this.setState({folderParam: folderParam, folderEditing:true});
    };

    uneditFolder = () => {
        this.updateFolderList();
        this.setState({folderParam: null, folderEditing:false});
    };


    saveListFolder = (folderList) => {
        this.setState({folderList:folderList});
        this.props.firebase.folders(this.state.client.id).set({
            folderList
        });
    };

    saveFolder = (folderId, folderName) => {
        this.props.firebase.folder(this.state.client.id, folderId).update({
            name:folderName
        });
        this.updateFolderList();
    };

    openFolder = (idFolder) => {
        this.setState({folderFilter:idFolder});
    };

    getFolderName () {
        const { t } = this.props;
        let folderName = t("scenarios.alltheflux");
        this.state.folderList.forEach(folder => {
            if (folder.id === this.state.folderFilter)
                folderName = folder.name;
        });

        return folderName;
    };

    saveScenario = (scenarioId, scenarioData) => {
        this.props.firebase
        .scenario(this.state.client.id, scenarioId)
        .update({
            scenarioData
        });
    };

    refreshScenarioList() {
        //Récupérer les scénarios sur OFC-backend
        axios.get('/getScenarios/' + this.props.client.id)
            .then(response => {
                // handle success
                this.updateListScenarios(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateListScenarios(scenarios) {
        const clientId = this.props.client.id;
        //Fondre les scénarios avec les infos de paramétrage présents sur firebase
        this.props.firebase.scenarios(this.state.client.id).on('value', snapshot => {
            let registeredParams = snapshot.val();
            if (registeredParams === null) {
                registeredParams = [];
            }

            if (Array.isArray(scenarios)) {
                let usableParams = [];
                scenarios.map(itKey => {
                    if (!registeredParams[itKey.id] || !registeredParams[itKey.id].name) {
                        usableParams[itKey.id] = {id: itKey.id, name:itKey.name, guid:itKey.guid, folder:'all', folders:this.state.folderList, chipStart:'ebp', chipEnd:'prestashop', clientId : clientId, responseDatas:"",responseType:""};
                    } else {
                        usableParams[itKey.id] = {
                            id: itKey.id,
                            name:registeredParams[itKey.id].name,
                            folder:(registeredParams[itKey.id].folder) ? registeredParams[itKey.id].folder : 'all',
                            folders:this.state.folderList,
                            chipStart:(registeredParams[itKey.id].chipStart) ? registeredParams[itKey.id].chipStart : 'ebp',
                            chipEnd:(registeredParams[itKey.id].chipEnd) ? registeredParams[itKey.id].chipEnd : 'prestashop',
                            clientId : clientId,
                            guid: (registeredParams[itKey.id].guid) ? registeredParams[itKey.id].guid :itKey.guid,
                            responseDatas:registeredParams[itKey.id].responseDatas,
                            responseType:registeredParams[itKey.id].responseType
                        };
                    }

                    /*return <Scenario key={itKey.id}
                                     clientId={clientId}
                                     idScenario={itKey.id}
                                     name={usableParams[itKey.id].name}
                                     folderFilter={this.state.folderFilter}
                                     folder={usableParams[itKey.id].folder}
                                     chipStart={usableParams[itKey.id].chipStart}
                                     chipEnd={usableParams[itKey.id].chipEnd}
                                     clicked={() => this.loadScenario(itKey.id)}
                                     editParams={() => this.editScenario(usableParams[itKey.id])}/>*/

                    //return <Scenario key={itKey.id} idScenario={itKey.id} name={itKey.name} clicked={() => this.loadScenario(6)}/>

                });

                this.setState({listScenarios: usableParams, loading: false});

            }
        });
    }

    loadScenario = (idScenario, name) => {
        this.setState({loadingScenario: true});
        axios.get('/getDetailScenarios/' + idScenario)
            .then(response => {
            // handle success
            if(this.state.loadingScenario)
                if(response.data) {
                    this.props.show(response.data, this.state.listScenarios[idScenario]);
                } else {
                    const scenarioInfos = {id:idScenario, name:name};
                    this.props.show(scenarioInfos, this.state.listScenarios[idScenario]);
                }
                this.props.history.push(ROUTES.DIAGRAM);
                //console.log(JSON.stringify(response.data.scenarioSteps[0]));

            })
            .catch(error => {
                console.log(error);
            });

    };

    editScenario = (scenarioParams) => {
        this.setState({scenarioParams: scenarioParams, editing:true});
    };

    uneditScenario = () => {
        this.setState({scenarioParams: null, editing:false});
        this.refreshScenarioList();
    };

    cancelLoadScenario = () => {
        this.setState({loadingScenario: false});
    };

    playFlux = (guid) => {
        axios.get('/lance/scenario/' + guid)
            .then(response => {
                // handle success
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    copyLink = (guid) => {
        const fluxURL = axios.defaults.baseURL + 'lance/scenario/' + guid;
        console.log(fluxURL);
        navigator.clipboard.writeText(fluxURL);
    };

    deleteFlux = (idFlux) => {
        console.log(this.state.listScenarios[idFlux].guid);
        axios.get('/deleteFlux/' + this.state.listScenarios[idFlux].guid)
            .then(response => {
                // handle success
                console.log(response.data);

                this.props.firebase.scenario(this.state.client.id, idFlux).remove();
                this.refreshScenarioList();
            })
            .catch(error => {
                console.log(error);
            });
    };

    render () {
        const { t } = this.props;
        const clientName = this.state.client.name;
        const folderName = (this.state.folderFilter === 'all') ? t("scenarios.alltheflux") : this.getFolderName();
        let pageContent = null;
        if(this.state.loading) {
            pageContent = <Spinner />;
        } else {
            const listScenario = this.state.listScenarios.map(itKey => {

                return <Scenario key={itKey.id}
                                 clientId={itKey.clientId}
                                 idScenario={itKey.id}
                                 name={itKey.name}
                                 folderFilter={this.state.folderFilter}
                                 folder={itKey.folder}
                                 chipStart={itKey.chipStart}
                                 chipEnd={itKey.chipEnd}
                                 clicked={() => this.loadScenario(itKey.id, itKey.name)}
                                 editParams={() => this.editScenario(itKey)}
                                 playFlux={() => this.playFlux(itKey.guid)}
                                 copyLink={() => this.copyLink(itKey.guid)}
                                 deleteFlux={() => this.deleteFlux(itKey.id)}/>

            });
            pageContent = <div className="list-group list-group-item-action list-group-flush">
                {listScenario}

                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend"
                        onClick={() => this.editScenario({clientId:this.state.client.id})}>
                    <i className="material-icons">add</i></button>
            </div>;
        }

        return (
            <React.Fragment>
            <Modal show={this.state.loadingScenario} modalClosed={this.cancelLoadScenario}>
                <p>{t("scenarios.loading")}</p>
                <Spinner />
            </Modal>

                <Modal show={this.state.editing} modalClosed={this.uneditScenario}>
                    {this.state.editing && <ScenarioParams scenarioParamsInfos={this.state.scenarioParams} onSubmit={this.uneditScenario}/>}
                </Modal>

                <Modal show={this.state.folderEditing} modalClosed={this.uneditFolder}>
                    {this.state.folderEditing && <FolderParam folderParamInfos={this.state.folderParam} clientId={this.state.client.id} onSubmit={this.uneditFolder}/>}
                </Modal>

                <nav className="navbar fixed-top navbar-dark scenarios-navbar">
                    <span className="navbar-brand mb-0 h1 scenarios-navbar-brand">{clientName} : {t("scenarios.flux")}</span>
                </nav>
            <Drawer folderList={this.state.folderList}
                    editFolder={this.editFolder}
                    createFolder
                    openFolder={this.openFolder}/>
            <div className="jumbotron scenarios">
                <h1 className="display-4">{folderName}</h1>
                <hr className="my-4"/>
                {pageContent}

            </div>

            </React.Fragment>
        );
    }
};


const TranslatedScenarios = withTranslation()(Scenarios);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(TranslatedScenarios);