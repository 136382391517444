import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import setjson from 'lodash/set';
import ListeStructure from './ListeStructure';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import './JSONEditorReact.css';

export default class JSONEditorReact extends Component {

  setNodeValue = (value) => {
    console.log(this.absolute);
    let currentjson = this.jsoneditor.get();
    setjson(currentjson, this.absolute, value);
    this.jsoneditor.update(currentjson);
  }

  absolute = "";
  onEvent = (node, event) => {
    if (event.type === 'click') {
      this.absolute = "";
      for (let i = 0; i < node.path.length; i++) {
        // console.log(typeof node.path[i]);
        if (typeof node.path[i] === 'number') {
          this.absolute = this.absolute.substring(0, this.absolute.length - 1);
          this.absolute += '[' + node.path[i] + ']';

        } else {
          this.absolute += node.path[i];
        }
        if (i !== node.path.length - 1) this.absolute += ".";
      }
      console.log(this.absolute);
    }
  };

  componentDidMount() {
    // copy all properties into options for the editor
    // (except the properties for the JSONEditorReact component itself)
    const options = Object.assign({}, this.props);
    delete options.json;
    delete options.text;
    if (options.modetree) options.onEvent = this.onEvent;
    this.jsoneditor = new JSONEditor(this.container, options);

    if ('json' in this.props) {
      this.jsoneditor.set(this.props.json);
    }
    if ('text' in this.props) {
      this.jsoneditor.setText(this.props.text);
    }
    this.schema = cloneDeep(this.props.schema);
    this.schemaRefs = cloneDeep(this.props.schemaRefs);
  }

  componentWillUpdate(nextProps, nextState) {
    if ('json' in nextProps) {
      this.jsoneditor.update(nextProps.json);
    }

    if ('text' in nextProps) {
      this.jsoneditor.updateText(nextProps.text);
    }

    if ('mode' in nextProps) {
      this.jsoneditor.setMode(nextProps.mode);
    }

    // store a clone of the schema to keep track on when it actually changes.
    // (When using a PureComponent all of this would be redundant)
    const schemaChanged = !isEqual(nextProps.schema, this.schema);
    const schemaRefsChanged = !isEqual(nextProps.schemaRefs, this.schemaRefs);
    if (schemaChanged || schemaRefsChanged) {
      this.schema = cloneDeep(nextProps.schema);
      this.schemaRefs = cloneDeep(nextProps.schemaRefs);
      this.jsoneditor.setSchema(nextProps.schema, nextProps.schemaRefs);
    }
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  render() {
    const style = this.props.modetree?{ overflow: 'auto', width: '500px', height: '300px' }:{ overflow: 'auto', width: '500px', height: '800px' };
    const overlaystyle = { background: 'rgba(0, 0, 0, 0)' };
    return (
      <React.Fragment>

        {this.props.modetree ?
          <div className="row">
            <div className="col-6">
              <div className="jsoneditor-react-container" ref={elem => this.container = elem} />
            </div>
            <div className="col-6">
              <ListeStructure setNodeValue={this.setNodeValue} />
            </div>
          </div>          :
          <div className="jsoneditor-react-container" ref={elem => this.container = elem} />}

      </React.Fragment>
    );
  }
}
