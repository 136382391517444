import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase/Context/Context';
import * as ROUTES from '../../config/routes';
import { Grid } from '@material-ui/core';


import FormOFC from '../UI/Forms/FormOFC';
import * as Yup from 'yup';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';

import { withTranslation } from 'react-i18next';


const PasswordForgetPage = () => (
    <div>
        <h1>Mot de passe oublié</h1>
        <PasswordForgetForm />
    </div>
);

const INITIAL_STATE = {
    email: '',
    error: null,
};

const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;
        const { t } = this.props;

        const isInvalid = email === '';

        const helperText = t("form.requis");

        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label={t("form.email")}
                        name="email"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={email}
                    />)
            },
        ];*/

        const formFields = <Grid item xs={12} key={1}>
            <TextField
                label={t("form.email")}
                name="email"
                required={required.name}
                helperText={helperText}
                onChange={this.onChange}
                value={email}
            />
        </Grid>;


        return (


            <React.Fragment>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         isValid={!isInvalid}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
            /*<form onSubmit={this.onSubmit}>
                <input
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                />
                <button disabled={isInvalid} type="submit">
                    Reset My Password
                </button>

                {error && <p>{error.message}</p>}
            </form>*/
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>Mot de passe oublié?</Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(withTranslation()(PasswordForgetFormBase));

export { PasswordForgetForm, PasswordForgetLink };