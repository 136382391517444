import React, { useContext } from 'react';
import { StructureContext } from '../Structure/StructureContext';
import JSONEditorReact from './JSONEditorReact';

function ListeStructure(props) {
    const { structureList, setStructureList } = useContext(StructureContext);

    const prettyPrintPath = (nodepath) => {
        var str = '';
        for (var i = 0; i < nodepath.length; i++) {
            var element = nodepath[i];
            // if (typeof element === 'number') {
            //   str += '[' + element + ']'
            // } else {
            if (str.length > 0) str += '.';
            str += element;
            // }
        }
        return '¤'+str+'¤';
    }
    const onEvent = (node, event) => {
        if (event.type === 'click') {            
            props.setNodeValue(prettyPrintPath(node.path));
                        
        }
    };

    return (
        <React.Fragment>
            
        <div>
            {
                Object.keys(structureList).map((item, index) => (
                    <React.Fragment>
                        <label>¤{structureList[item]['id_node']} {structureList[item]['name']}</label>
                        <JSONEditorReact
                            //   schema={this.state.schema}
                            mainMenuBar={false}
                            navigationBar={false}
                            search={false}
                            text={JSON.stringify(structureList[item]['data'], null, 2)}
                            mode='view'
                            //   modes={modes}
                            indentation={4}
                            // onChangeText={this.onChangeText}
                            // onModeChange={this.onModeChange}
                            onEvent={onEvent}
                            id_node={structureList[item]['id_node']}
                        />
                    </React.Fragment>
                ))
            }
        </div>
        </React.Fragment>
    )
}

export default ListeStructure;