import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../UI/Modal/Modal';
import { withFirebase } from '../../Firebase';
import * as ROLES from '../../../config/roles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';

import FormOFC from '../../UI/Forms/FormOFC';
import * as Yup from 'yup';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';
import FilterSelect from 'react-select';

import FormControl from '@material-ui/core/FormControl';
import systems from "../../../assets/node-icons/systems/";
import types, {groupedOptions} from "./systemTypes";
import axios from "../../../axios-base";

import { withTranslation } from 'react-i18next';

// TODO : implement this class
const INITIAL_STATE = {
    clientId: null,
    id: null,
    name: '',
    folder: '/',
    folders:[],
    chipStart : '',
    chipEnd : '',
    guid: '',
    error: null,
};
const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class ScenarioParams extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount () {
        this.setState({...this.props.scenarioParamsInfos})
    }

    onSubmit = event => {
        const { clientId, id, name,  folder, chipStart, chipEnd, guid } = this.state;
        if(!id) {
            this.createScenario();
        }
        if(id) {
            this.props.firebase
                .scenario(clientId, id)
                .update({
                    name,
                    folder,
                    chipStart,
                    chipEnd
                })
                .then(() => {
                    this.props.onSubmit();
                })
                .catch(error => {
                    this.setState({ error });
                });
        }
        //event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeSelect = (event, meta) => {
        //console.log(event);
        //console.log(meta);
        const targetName = meta.name;
        if(targetName && event) {
            this.setState({ [targetName]: event.value });

            console.log(targetName);
            console.log(this.state);
        }

    };


    createScenario = () => {
        const { clientId, name,  folder, chipStart, chipEnd } = this.state;
        console.log(clientId);
        console.log(this.props.scenarioParamsInfos);

        axios.post('/createFlux/' + clientId, {name:name})
            .then(response => {
                const id = response.data.id_scenario;
                const guid = response.data.guid;
                if (!id || !guid)
                    return null;
                // handle success
                this.props.firebase
                    .scenario(clientId, id)
                    .set({
                    guid,
                    name,
                    folder,
                    chipStart,
                    chipEnd
                })
                    .then(() => {
                        this.setState({id:id});
                        this.props.onSubmit();
                    })
                    .catch(error => {
                        this.setState({ error });
                    });
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    render() {
        const {
            name,
            folder,
            chipStart,
            chipEnd,
            error
        } = this.state;
        const { t } = this.props;

        const helperText = t("form.requis");

        const isInvalid =
            name === '';
        let chipEndKey = 0;
        let chipStartKey = 0;
        const chipValues = types.map((itKey, key) => {
            if (itKey.code === chipEnd)
                chipEndKey = key;
            if (itKey.code === chipStart)
                chipStartKey = key;

            let sysImg = systems[itKey.code];
            //return {label: <div style={{display:"flex", justifyContent:"space-between",width:"100%", verticalAlign:"middle"}}>{itKey.name} <img className={"tuIcon"} src={sysImg} /></div>, value: itKey.code};
            return {label: <div><img className={"tuIcon"} src={sysImg} /> {itKey.name}</div>, value: itKey.code};

        });

        const formatGroupLabel = data => (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <span>{data.label}</span>
                <span style={{
                    backgroundColor: '#EBECF0',
                    borderRadius: '2em',
                    color: '#172B4D',
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 'normal',
                    lineHeight: '1',
                    minWidth: 1,
                    padding: '0.16666666666667em 0.5em',
                    textAlign: 'center',
                }}>{data.options.length}</span>
            </div>
        );

        const folderValues = this.state.folders.map(itKey => {
            return {label: itKey.name, value: itKey.id};

        });

        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t("form.nom")}
                    name="name"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={name}
                />
            </Grid>
            <Grid item xs={6} key={2}>
                <FilterSelect
                    onChange={this.onChangeSelect}
                    label={t("form.chipStart")}
                    name="chipStart"
                    isSearchable
                    options={chipValues}
                    value={chipValues[chipStartKey]}
                />
            </Grid>
            <Grid item xs={6} key={3}>
                <FilterSelect
                    onChange={this.onChangeSelect}
                    label={t("form.chipEnd")}
                    name="chipEnd"
                    isSearchable
                    options={chipValues}
                    value={chipValues[chipEndKey]}
                />
            </Grid>
            <Grid item xs={12} key={4}>
                <Select
                    onChange={this.onChange}
                    label={t("form.nomRepertoire")}
                    name="folder"
                    required={required.folder}
                    data={folderValues}
                    multiple={false}
                    value={folder}
                />
            </Grid>
        </React.Fragment>;


        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label={t("form.nom")}
                        name="name"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={name}
                    />)
            },
            {
                size: 6,
                field: (
                    <FilterSelect
                        onChange={this.onChangeSelect}
                        label={t("form.chipStart")}
                        name="chipStart"
                        isSearchable
                        options={chipValues}
                        value={chipValues[chipStartKey]}
                    />)
            },
            {
                size: 6,
                field: (
                    <FilterSelect
                        onChange={this.onChangeSelect}
                        label={t("form.chipEnd")}
                        name="chipEnd"
                        isSearchable
                        options={chipValues}
                        value={chipValues[chipEndKey]}
                    />)
            },
            {
                size: 12,
                field: (
                    <Select
                        onChange={this.onChange}
                        label={t("form.nomRepertoire")}
                        name="folder"
                        required={required.folder}
                        data={folderValues}
                        multiple={false}
                        value={folder}
                    />)
            }
        ];*/


        return (

            <React.Fragment>
                <h1>{t("scenarios.parametres")}</h1>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
        );
    }
}


const CompleteScenarioParams = withRouter(withFirebase(withTranslation()(ScenarioParams)));

export default CompleteScenarioParams;