import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Grid, Button, Paper, CssBaseline } from '@material-ui/core';
import { makeValidate } from 'mui-rff';
import arrayMutators from "final-form-arrays";

function FormOFC(props) {
    const { initialValues, schema, formFields } = props;
    const [submittedValues, setSubmittedValues] = useState();
    const validate = makeValidate(schema);
    const onReset = (form) => {
        return () => {
            setSubmittedValues(undefined);
            form.reset();
        };
    };


    function onSubmit(values) {
        setSubmittedValues(values);
        console.log(values);
        props.onSubmit(values);
        if(props.closePopup) {
            props.closePopup();
        }
    }

    const isValid = (typeof props.isValid !== 'undefined') ? props.isValid : true;

    return (
        <React.Fragment>
            <CssBaseline />
            <pre>
                {JSON.stringify(
                    submittedValues ? submittedValues : {},
                    undefined,
                    2,
                )}
            </pre>
            
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={submittedValues ? submittedValues : initialValues}
                subscription={true}
                validate={validate}
                // key={true}
                render={({ handleSubmit,
                    submitting,
                    form: {
                        mutators: { push, pop }
                    },
                    form
                }) => (                    
                        <form                        
                            onSubmit={handleSubmit}
                            noValidate={true}
                        // autoComplete="new-password"
                        >
                            <Paper style={{ padding: 16, margin: 5 }}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {/* <Grid item xs={6}> */}
                                    {formFields}
                                        <Grid item > {/*  className={classes.buttons} */}
                                            <Button
                                                type="button"
                                                variant="contained"
                                                onClick={onReset(form)}
                                                disabled={submitting}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            // onClick={onSubmit(form)}
                                                disabled={!isValid}
                                            >
                                                Sauvegarde
                                            </Button>
                                        </Grid>
                                    </Grid>
                                {/* </Grid> */}
                            </Paper>
                        </form>
                    )}
            />
        </React.Fragment>
    );
}

export default FormOFC;