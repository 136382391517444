import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import * as Yup from 'yup';
import FormOFC from '../../UI/Forms/FormOFC';
import {TextField} from 'mui-rff';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const schema = Yup.object().shape({});

class ToolboxImport extends Component {
    render() {

        const {t} = this.props;
        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t('toolbox.jsondatas')}
                    name="jsondatas"
                    multiline={true}
                    rows= {15}
                />
            </Grid>
        </React.Fragment>;
        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label="Json du flux"
                        name="jsondatas"
                        multiline={true}
                        rows= {15}
                    />)
            }

        ];*/
        // const jsondatas = this.props.exportScenario();        
        return (

            <React.Fragment>
                <h1>Import / Export</h1>
                <hr className="my-4"/>
                <FormOFC 
                         schema={schema}
                         formFields={formFields}
                         closePopup={this.props.closePopup}
                         onSubmit={(formData, e) => this.props.importScenario(formData)}
                />
            </React.Fragment>
        );
    }
}


const CompleteToolboxParamsImport = withRouter(withFirebase(withTranslation('diagram')(ToolboxImport)));

export default CompleteToolboxParamsImport;