import * as React from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import './OFCSuperNodeWidget.css';
import data from '../../../assets/node-icons';
import typeReader from '../ReadTypeTU';
import {CatalogTypesNonTranslated} from '../CatalogTypesTranslated';
import Tooltip from '@material-ui/core/Tooltip';
import Popup from "reactjs-popup";
import Editable from "../../../components/Editable/Editable";

import {TranslationContext} from "../../../context/TranslationContext";


export class OFCSuperNodeWidget extends React.Component {

    static contextType = TranslationContext;

	state = {
        children: []
	};

	componentDidMount () {
        if (this.props.node.options.children)
            this.setState({children:this.props.node.options.children});
    }

	addChildren = (type) => {
	    const types = this.context.types;
	    const ordre = this.state.children.length + 1;
        if(this.props.node.addChildren(type, ordre, types))
            this.setState({children:this.props.node.options.children});
    };

	deleteChilren = (key) => {
        if(this.props.node.removeChildren(key))
            this.setState({children:this.props.node.options.children});
    };

	moveUp = (key) => {
        if(this.props.node.moveUp(key))
            this.setState({children:this.props.node.options.children});
	};

    moveDown = (key) => {
        if(this.props.node.moveDown(key))
            this.setState({children:this.props.node.options.children});
    };


	render() {

        const types = this.context.types;

        const formatedItems = types.map(itKey => {
            let tuImg = data[types[itKey.id].code];
            return <div className="tu-item col-12" key={itKey.id} onClick={() => this.addChildren(itKey.id)}>{itKey.name} <img className={"tuIcon"} src={tuImg} /></div>
        });

		let type = typeReader(11);

        let divStyle = {
            backgroundImage: 'url(' + data[type['code']] + ')'
        };

        /**const nodeChildren = this.props.options.children.map(itKey => {

            return <div className={"super-node-children"}>
                <div className={"sn-children-title"}>{itKey.title}</div>
                <div className={"sn-children-icon"}></div>
                <button type="button" className="btn btn-light node-toolbox-button" onClick={() => this.props.node.options.fctparam(
                    type['code'],
                    this.props.node.options.name,
                    this.props.node.options.params,
                    this.props.node.options.schema,
                    this.props.node.options.id,
                    this.props.node.options.id_node)}>
                    <i className="material-icons">build</i>
                </button>
            </div>;
		});*/

        //const childrenData = [{title: "Test", code: "httpsimple"}];
        const nodeChildren = (this.state.children.length < 1) ? null : this.state.children.map((itKey, index) => {
        	const title = (itKey.name.length > 35) ? itKey.name.substring(0,32) + '...' :  itKey.name;
        	console.log(title);
            let tuImg = data[types[itKey.typeTU].code];

            return <div className={"super-node-children"} key={itKey.id_node}>
                <div  className={"sn-children-title-block"}>
                    <div className={"order-info order-info-label"}>{itKey.id_node}</div>
                    <div className={"sn-children-title"}>
                        <Editable text={title} idNode={itKey.id_node} updateName={this.props.node.updateChildName}
                        >
                        </Editable>
                    </div>
                </div>

                <img className={"sn-tuIcon"} src={tuImg} />
                <div className="btn-group btn-group-sm super-node-children-toolbox">
                    <Tooltip title={"Déplacer vers l'avant"}>
                        <button type="button" className="btn btn-light sn-children-button" onClick={() => this.moveUp(index)}>
                            <i className="material-icons">arrow_drop_up</i>
                        </button>
                    </Tooltip>

                    <Tooltip title={"Déplacer vers l'arrière"}>
                        <button type="button" className="btn btn-light sn-children-button" onClick={() => this.moveDown(index)}>
                            <i className="material-icons">arrow_drop_down</i>
                        </button>
                    </Tooltip>
                    <Tooltip title={"Paramètres"}><button type="button" className="btn btn-light sn-children-button" onClick={() => this.props.node.options.fctparam(
                        types[itKey.typeTU].code,
                        itKey.name,
                        itKey.params,
                        itKey.schema,
                        this.props.node.options.id,
                        itKey.id_node)}>
                        <i className="material-icons">build</i>
                    </button></Tooltip>

                    <Tooltip title={"Exécuter le traitement"}>
                        <button type="button" className="btn btn-light sn-children-button">
                            <i className="material-icons">play_arrow</i>
                        </button>
                    </Tooltip>
                    <Tooltip title={"Supprimer"}>
                        <button type="button" className="btn btn-light sn-children-button" onClick={() => this.deleteChilren(index)}>
                            <i className="material-icons">delete_forever</i>
                        </button>
                    </Tooltip>
                </div>
            </div>;
        });


        return (
			<React.Fragment>
			<div className="custom-node">

				<PortWidget engine={this.props.engine} port={this.props.node.getPort('in')}>
					<div className="circle-port" />
				</PortWidget>
				<div className={"custom-super-node-container"}>
					<div className={"super-node-header"}>
						<div className="custom-super-node-image" style={divStyle}  onClick={this.props.node.options.getpos}/>

						<div className="btn-group btn-group-sm super-node-toolbox">

							<Tooltip title={"Paramètres"}><button type="button" className="btn btn-light super-node-toolbox-button" onClick={() => this.props.node.options.fctparam(
								type['code'],
								this.props.node.options.name,
								this.props.node.options.params,
								this.props.node.options.schema,
								this.props.node.options.id,
								this.props.node.options.id_node)}>
								<i className="material-icons">build</i>
							</button></Tooltip>

                            <Popup position="right center"
                                   contentStyle = {{width : '400px', height: '500px', overflowY:'auto',overflowX:'hidden'}}
                                   trigger={
                                       <Tooltip title={"Ajouter un traitement à la boucle"}>
                                           <button type="button" className="btn btn-light super-node-toolbox-button">
                                               <i className="material-icons">add</i>
                                           </button>
                                       </Tooltip>
                                   }>

                                {close => (
                                    <React.Fragment>
                                        <a className="close" onClick={close}>
                                            &times;
                                        </a>
                                        <div className="Toolbox-menu row">
                                            {formatedItems}
                                        </div>
                                    </React.Fragment>
                                )}
                            </Popup>

							<Tooltip title={"Exécuter le traitement"}>
								<button type="button" className="btn btn-light super-node-toolbox-button" onClick={() => this.props.node.options.fctexecute(this.props.node.options.id_node)}>
								<i className="material-icons">play_arrow</i>
								</button>
							</Tooltip>
							<Tooltip title={"Supprimer"}>
							<button type="button" className="btn btn-light super-node-toolbox-button" onClick={this.props.node.options.removeModel}>
								<i className="material-icons">delete_forever</i>
							</button>
							</Tooltip>
						</div>
                    </div>


                    <div className={"super-node-body"}>
						{nodeChildren}
					</div>
                </div>
				<PortWidget engine={this.props.engine} port={this.props.node.getPort('out')}>
					<div className="circle-port" />
				</PortWidget>
			</div>
				<div className={"row sn-legend"}>

					<div className={"order-info order-info-label"}>{this.props.node.options.id_node ? this.props.node.options.id_node.toString() : '0'}</div>

				<div className="sn-name">
                        <div>{this.props.node.options.name}</div>

				</div>

                </div>
			</React.Fragment>
		);
	}
}