import React from 'react';
import systemIcons from '../../assets/node-icons/systems';
import './Chip.css';



const chip = (props) => {
    return (

        <div className={"chip"}>
            <div className="chip-head"><div className={"chip-icon"}><img alt="Natacha" src={systemIcons[props.start]} className={"chip-icon-img"} /></div></div>
            <div className="chip-content"><i className="material-icons">double_arrow</i></div>
            <div className="chip-close"><div className={"chip-icon"}><img alt="Natacha" src={systemIcons[props.end]} className={"chip-icon-img"} /></div></div>
        </div>
    );
};



export default chip;