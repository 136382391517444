import React from 'react';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { OFCPortModel } from './OFCPortModel';

export class OFCPortFactory extends AbstractModelFactory {
	constructor() {
		super('ofcport');
	}

	generateModel() {
		return new OFCPortModel();
	}
}
