import React, {Component} from 'react';
import axios from '../../axios-base';
import Client from './Client/Client';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';

import FilterResults from 'react-filter-search';
import Search from '../Search/Search';
import './Clients.css';
import ClientParams from './ClientParams/ClientParams';
import {ConnexionsContext} from '../../context/ConnexionsContext';
import withAuthorization from '../Session/withAuthorization/withAuthorization';
import * as ROUTES from "../../config/routes";

import { withTranslation } from 'react-i18next';

class Clients extends Component {
    static contextType = ConnexionsContext;
    // state = {
    //     client:this.props.client,
    //     listScenarios: [],
    //     Scenarios: false,
    //     Scenario: false,
    //     loading: true,
    //     loadingScenario: false
    // };
    state = {
         client:null,
        value:'',
        clients:null
     };

    componentDidMount () {
        this.getAllClients();
    }

    getAllClients = () => {
        //console.log(this.props.userId);
        axios.get('/getCustomers')
            .then(response => {
                const clients = response.data;
                this.setState({ clients: clients });

            })
            .catch(error => {
                console.log(error);
                return null;
            });

        /*{'id':1, 'name':'Test'};
        const fakeClients = [{ id: 3, company: 'AGR', logo: 'wittenstein' },
        { id: 2, company: 'BUISINE', logo: 'fastcooling' },
        { id: 6, company: 'Derma Trucks', logo: 'wittenstein' },
        { id: 8, company: 'MaSolution Dev', logo: 'wittenstein' },
        { id: 5, company: 'MY RETAIL BOX', logo: 'wittenstein' },
        { id: 7, company: 'Objetrama', logo: 'wittenstein' },
        ];*/
    };


    getAllTuSchemas = (clientData) => {
        this.setState({client:clientData});

        // this.props.firebase.client(clientData.id).once('value').then(snapshot => {
        //     const client = snapshot.val();
        //     const clientSchemas = client.schema ? client.schema : null;

        //     if(!clientSchemas) {
        //         axios.get('/getSchemas/' + clientData.id )
        //             .then(response => {
        //                 if(response.data) {
        //                     this.updateTuSchema(response.data);
        //                     this.props.show(clientData, response.data);
        //                 }
        //                 console.log(response);
        //                 this.props.history.push(ROUTES.SCENARIOS);
        //             })
        //             .catch(error => {
        //                 console.log(error);
        //             });
        //     }
        //     if(clientSchemas) {
        //         this.props.show(clientData, clientSchemas);
        //         this.props.history.push(ROUTES.SCENARIOS);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
        this.props.show(clientData, null);
        this.props.history.push(ROUTES.SCENARIOS);
        const clientId = clientData.id;
        axios.get('/getConnexions/' + clientId)
            .then(response => {
                this.context.setConnexionsList(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateTuSchema = (data) => {
        /*firebase.put('/client/' + this.state.client.id + '/schema.json', data)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });*/
        this.props.firebase.client(this.state.client.id)
            .update({
                schema: data
            })
            .catch(error => {
                console.log(error);
        });
    };

    editClient = (clientParams = null) => {
        this.setState({clientParams: clientParams, editing:true});
    };

    uneditClient = () => {
        this.setState({clientParams: null, editing:false});
    };

    handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };



    render () {

        //let listScenarios = [{id:4, id_client_id:2, name: "exact get clients"}, {id:2, id_client_id:2, name: "exact get client detail"}, {id:5, id_client_id:2, name: "récup invoice"}, {id:6, id_client_id:2, name: "maj articles"}];

        // const clientName = this.state.client.name;
        let pageContent = null;
        const { value } = this.state;
        const {t} = this.props;
        // if(this.state.loading) {
        //     pageContent = <Spinner />;
        // } else {
        //if(this.props.clients) {
        if(this.state.clients) {
            const formatedItems = <FilterResults
                value={value}
                data={this.state.clients}
                renderResults={results => (
                    <div>
                        {results.map(itKey => (
                            <Client key={itKey.id} idClient={itKey.id} name={itKey.company} clicked={() => this.getAllTuSchemas({id:itKey.id, name:itKey.company})}/>
                        ))}
                    </div>
                )}
                />;
            /*const formatedItems = this.props.clients.map(itKey => {
                //return <Client key={itKey.id} idClient={itKey.id} name={itKey.name} clicked={() => this.props.show({id:itKey.id, name:itKey.name})}/>

                return <Client key={itKey.id} idClient={itKey.id} name={itKey.company} clicked={() => this.getAllTuSchemas({id:itKey.id, name:itKey.company})}/>
            });*/
            pageContent = <div className="list-group list-group-item-action list-group-flush">
                {formatedItems}

                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend client-button"
                        //onClick={() => this.props.show({scenarioSteps:[]})}>
                    onClick={() => this.editClient()}>
                    <i className="material-icons">add</i></button>
            </div>;
        } else {
            pageContent = <Spinner />;
        }
        // }

        return (
            <React.Fragment>
            {/* <Modal show={this.state.loadingScenario} modalClosed={this.cancelLoadScenario}>
                <p>Chargement de la liste des clients ...</p>
                <Spinner />
            </Modal> */}

                <Modal show={this.state.editing} modalClosed={this.uneditClient}>
                    {this.state.editing && <ClientParams clientParamsInfos={this.state.clientParams} onSubmit={this.uneditClient}/>}
                </Modal>

            <div className="jumbotron clients">
                <h1 className="display-4">{t("client.titre")}</h1>
                <hr className="my-4"/>
                {/* <p className="lead">Liste des scénarios pour {clientName}.</p> */}
                <Search value={this.state.value} onChange={this.handleChange}/>

                {pageContent}

            </div>
            </React.Fragment>
        );
    }
};


const condition = authUser => !!authUser;


export default withAuthorization(condition)(withTranslation()(Clients));