import * as React from 'react';
import { OFCSuperNodeModel } from './OFCSuperNodeModel';
import { OFCSuperNodeWidget } from './OFCSuperNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

export class OFCSuperNodeFactory extends AbstractReactFactory {
	constructor() {
		super('ofc-super-node');
	}

	generateModel(event) {
		return new OFCSuperNodeModel();
	}

	generateReactWidget(event) {
		return <OFCSuperNodeWidget engine={this.engine} node={event.model} />;
	}
}

