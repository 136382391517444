import React from 'react';

import Popup from "reactjs-popup";
import FormOFC from '../../UI/Forms/FormOFC';
const style = { overflow: 'auto', width: '600px', height: '500px' };

// const required = makeRequired(schema);
const Connexion = (props) => {
    return (
        <Popup
            modal={true}
            position="right top"
            contentStyle={style}
            // onClose={() => document.getElementById(props.id).focus()}
            trigger={
                <a type="button" className="btn list-group-item list-group-item-action input-group-prepend client-button"  >
                    {props.name}
                </a>
            }>
            <FormOFC initialValues={props.params}
                schema={props.schema}
                formFields={props.formFields}
                onSubmit={(formData, e) => props.updateConnexion(formData)}
            />                            
        </Popup>

    );
};

export default Connexion;