let systemTypes = [];
systemTypes[0] = {'id':0,'code':'system','name':'Système'};
systemTypes[1] = {'id':1,'code':'cegid','name':'Cegid'};
systemTypes[2] = {'id':2,'code':'ebp','name':'EBP'};
systemTypes[3] = {'id':10,'code':'epages','name':'Epages'};
systemTypes[4] = {'id':9,'code':'exact','name':'Exact'};
systemTypes[5] = {'id':8,'code':'hubspot','name':'HubSpot'};
systemTypes[6] = {'id':3,'code':'magento','name':'MAGENTO'};
systemTypes[7] = {'id':4,'code':'prestashop','name':'Prestashop'};
systemTypes[8] = {'id':5,'code':'sage','name':'Sage'};
systemTypes[9] = {'id':6,'code':'shopify','name':'Shopify'};
systemTypes[10] = {'id':7,'code':'woocommerce','name':'WooCommerce'};

export default systemTypes;

let erp = [];
erp[1] = systemTypes[1];
erp[2] = systemTypes[2];
erp[5] = systemTypes[5];
erp[8] = systemTypes[8];


let cms = [];
cms[3] = systemTypes[3];
cms[4] = systemTypes[4];
cms[6] = systemTypes[6];
cms[7] = systemTypes[7];
cms[8] = systemTypes[8];

export const groupedOptions = [
    {
        label: 'ERP',
        options: erp,
    },
    {
        label: 'CMS',
        options: cms,
    },
];
