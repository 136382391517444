import React from 'react';
import * as Yup from 'yup';
import { Checkboxes, makeRequired } from 'mui-rff';
import CustomTextField from '../CustomTextField';
import CustomJsonField from '../CustomJsonField';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuCreateDataschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const required = makeRequired(TuCreateDataschema);

function TuCreateData () {
    const {t} = useTranslation('diagram');

    const helperText = t('param.requis');

    return (
        <React.Fragment>

            <Grid item xs={12} key={1}>
                <CustomJsonField
                    label={t('param.modelData')}
                    name="json_definition"
                    id="json_definition"
                    required={required.datas}
                    // helperText={helperText}
                />
            </Grid>

            <Grid item xs={12} key={2}>
                <Checkboxes
                    name={'okmononiveau'}
                    // required={required.best}
                    data={{ label:t('param.genMonoNiveau'), value: true }}
                    helperText={helperText}
                />
            </Grid>

            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t('param.datas')}
                    name="datas"
                    id="datas"
                />
            </Grid>

            <Grid item xs={12} key={4}>
                <CustomTextField
                    label={t('param.baliseboucle')}
                    name="baliseboucle"
                    id="baliseboucle"
                />
            </Grid>
        </React.Fragment>
    );
}

export default TuCreateData;