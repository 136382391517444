import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router } from "react-router-dom";

import Firebase from './components/Firebase/Firebase';
import FirebaseContext from './components/Firebase/Context/Context';
import ConnexionsProvider from './context/ConnexionsContext';
import './components/i18n/i18n';
import TranslationsProvider from './context/TranslationContext';
import PopupJsonProvider from './context/PopupJsonContext';


ReactDOM.render(
    <Router>
        <FirebaseContext.Provider value={new Firebase()}>

            <ConnexionsProvider>
                <PopupJsonProvider>
                    <Suspense fallback="loading">
                        <TranslationsProvider>
                            <App />
                        </TranslationsProvider>
                    </Suspense>
                </PopupJsonProvider>
            </ConnexionsProvider>
        </FirebaseContext.Provider>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
