import React, { useContext, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Popup from "reactjs-popup";
import JSONEditorReact from '../JsonEditor/JSONEditorReact';
import { StructureContext } from '../Structure/StructureContext';
import { Field } from 'react-final-form';
// import useMousePosition from '../../Hooks/useMousePosition';
import {useTranslation} from "react-i18next";


const CustomTextarea = function (props) {
    const { t, i18n } = useTranslation('diagram');
    // const user = useContext(UserContext);  
    const style = { overflow: 'auto', width: '600px', height: '500px' };
    const overlaystyle = {background:'rgba(0, 0, 0, 0)' };
    const { structureList, setStructureList } =  useContext(StructureContext);

    // const { xPos, yPos} = useMousePosition();

    const prettyPrintPath = (path) => {
        var str = '';
        for (var i = 0; i < path.length; i++) {
            var element = path[i];
            // if (typeof element === 'number') {
            //   str += '[' + element + ']'
            // } else {
            if (str.length > 0) str += '.';
            str += element;
            // }
        }
        return '¤'+str+'¤';
    }

    const onEvent = (node, event) => {
        if (event.type === 'click') {
            var message = 'click on <' + node.field +
                '> under path <' + node.path +
                '> with pretty path: <' + prettyPrintPath(node.path) + '>';
            if (node.value) message += ' with value <' + node.value + '>';
            node.value = 'toto' + node.value;            
            props.input.onChange(props.input.value + prettyPrintPath(node.path));
            props.input.onFocus();
            // console.log(props.input);
            document.getElementById(props.id).focus()
            // document.getElementsByName(props.input.name).focus
            //document.getElementById(props.id).onchange(prettyPrintPath(node.path));            
        }
    };

    const afterClose = () => {
        var e = window.event;
        var xPos = e.clientX;
        var yPos = e.clientY;
        setTimeout(() => {
            document.elementFromPoint(xPos,yPos).focus();
            document.elementFromPoint(xPos,yPos).click();
        }, 50);
    };
    
    const _onChange = ({
        target: { value },
    }) => props.onChange(value === '' ? props.options.emptyValue : value);
    const _onBlur = ({ target: { value } }) => props.onBlur(props.id, value);
    const _onFocus = ({ target: { value }, }) => props.onFocus(props.id, value);

    return (
        <Field name={props.name}>
            {fieldprops => {
                props = { ...props, ...fieldprops };
                return (
                    <Popup
                        modal={true}
                        position="left top"
                        contentStyle={style}
                        overlayStyle={overlaystyle}
                        //onClose={() => {document.getElementById(props.id).focus()}}
                        onClose={() => afterClose()}

                        trigger={
                            <FormControl
                                fullWidth={true}
                                //error={!!rawErrors}
                                required={props.required}
                            >
                                <TextField
                                    id={props.id}
                                    name={props.input.name}
                                    value={props.input.value}
                                    label={t(props.label) || t(props.schema.title)}
                                    onChange={props.input.onChange}
                                    onBlur={props.input.onBlur}
                                    onFocus={props.input.onFocus}
                                    placeholder={props.placeholder}
                                    disabled={props.disabled || props.readonly}
                                    required={props.required}
                                    autoFocus={props.autofocus}
                                    multiline={props.multiline}
                                    rows={props.rows}
                                />
                            </FormControl>
                        }>
                        {/* <StructureContext.Consumer>
                            {({ structureList }) => ( */}
                            {
                                Object.keys(structureList).map((item, index) => (
                                <Fragment>
                                <label>¤{structureList[item]['id_node']} {structureList[item]['name']}</label>
                                <JSONEditorReact
                                    //   schema={this.state.schema}
                                    mainMenuBar={false}
                                    navigationBar={false}
                                    search={false}
                                    text={JSON.stringify(structureList[item]['data'], null, 2)}
                                    mode='view'
                                    //   modes={modes}
                                    indentation={4}
                                    // onChangeText={this.onChangeText}
                                    // onModeChange={this.onModeChange}
                                    onEvent={onEvent}
                                    id_node={structureList[item]['id_node']}
                                />
                                </Fragment>
                                ))
                            }
                        {/* </StructureContext.Consumer> */}
                    </Popup>
                )
            }
            }
        </Field>
    );
}

export default CustomTextarea;