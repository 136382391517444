import React from 'react';
import * as Yup from 'yup';
import {TextField, Checkboxes, makeRequired} from 'mui-rff';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuShopifyschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const helperText = '* Requis';
const required = makeRequired(TuShopifyschema);


function TuShopify () {
    const {t} = useTranslation('diagram');

    return (
        <React.Fragment>

            <Grid item xs={12} key={1}>
                <TextField
                    label={t("param.username")}
                    name="username"
                />
            </Grid>

            <Grid item xs={12} key={2}>
                <TextField
                    label={t("param.password")}
                    name="password"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <TextField
                    label={t("param.sitename")}
                    name="sitename"
                />
            </Grid>
            <Grid item xs={12} key={4}>
                <TextField
                    label={t("param.query_string")}
                    name="query_string"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <Checkboxes
                    name="okfulldatas"
                    formControlProps={{ margin: 'none' }}
                    data={{ label: t("param.okfulldatas"), value: false }}
                />
            </Grid>
        </React.Fragment>
    );
}

export default TuShopify;