import React from 'react';
import * as ROUTES from '../../../config/routes';
import Tooltip from '@material-ui/core/Tooltip';
import './Client.css';

import { useTranslation } from 'react-i18next';


function Client (props) {
    const {t} = useTranslation();
    return (
        <div className="btn list-group-item list-group-item-action input-group-prepend client-button">
            <div className={"client-item"}>
                <div className={"client-item-name"} onClick={() => props.clicked(props.idClient)} >{props.name}</div>
                <div><Tooltip title={t("client.parametres")}><i className="material-icons">settings</i></Tooltip></div>
            </div>
        </div>
    );
};

export default Client;