import React from 'react';
import './NavigationItems.css';
import Logo from './../../Logo/Logo';
import NavigationItem from "./NavigationItem/NavigationItem";
import { withFirebase } from '../../Firebase/Context/Context';
import { Link } from 'react-router-dom';
import * as ROLES from '../../../config/roles';
import * as ROUTES from '../../../config/routes';
import { useTranslation } from 'react-i18next';

import AuthUserContext from './../../Session/Session';
import withAuthentication from "../../Session/withAuthentification/withAuthentification";

function processItems (items) {
    const formatedItems = items.map(itKey => {
        return <NavigationItem key={itKey.icon} icon={itKey.icon} name={itKey.name} clicked={itKey.clicked} link={itKey.link}/>
    });
    return formatedItems;
}

function NavigationItems (props) {
    const { t } = useTranslation();
    const clientName = props.profil.name ? props.profil.name : t("menu.profilUtilisateur");
    const clientItems = [
        {icon: "share", name: t("menu.flux"), link: ROUTES.SCENARIOS},
        {icon: "language", name: t("menu.connexions"), link: ROUTES.CONNEXIONS},
        {icon: "account_circle", name: t("menu.monCompte"), link: ROUTES.ACCOUNT}
    ];

    const adminItem = [
        {icon: "people", name: t("menu.clients"), link:ROUTES.ADMIN_CLIENTS},
        {icon: "account_box", name: t("menu.monCompte"), link: ROUTES.ADMIN_ACCOUNT},
        {icon: "account_tree", name: t("menu.gestionComptes"), link: ROUTES.ADMIN_ACCOUNTS}
        ];

    const userItem = [
        {icon: "people", name: t("menu.clients"), link:ROUTES.ADMIN_CLIENTS},
        {icon: "account_box", name: t("menu.monCompte"), link: ROUTES.ADMIN_ACCOUNT},
      ];

    const items = (props.theme === "admin") ? adminItem : clientItems;

    const formatedItems = items.map(itKey => {
        return <NavigationItem key={itKey.icon} icon={itKey.icon} name={itKey.name} clicked={itKey.clicked} link={itKey.link}/>
    });

    return <div className="sidebar-wrapper ps-container ps-theme-default">
            <Logo/>
            <hr/>
            <ul className="nav">
                <AuthUserContext.Consumer>
                    {
                        authUser => {
                            if (authUser && (props.theme === "admin")) {
                                if (!!authUser.roles[ROLES.ADMIN])
                                    return processItems(adminItem);

                                if (!!authUser.roles[ROLES.USER])
                                    return processItems(userItem);
                            }
                            if (authUser && props.theme === "client") {
                                if(!!authUser.roles[ROLES.USER] || !!authUser.roles[ROLES.ADMIN])
                                    return <React.Fragment>
                                        {processItems(clientItems)}
                                        <NavigationItem key={"arrow_back"} icon={"arrow_back"} name={t("menu.retourAdmin")} link={ROUTES.ADMIN_CLIENTS} />
                                    </React.Fragment>;
                                return processItems(clientItems);
                            }
                        }
                    }
                </AuthUserContext.Consumer>

                </ul>
            <div className={"nav-bottom"}>
                <ul className="nav">
                    <NavigationItem key={"power_settings_new"} icon={"power_settings_new"} name={t("menu.deconnexion")} clicked={props.firebase.doSignOut}/>
                </ul>
            </div>
        </div>
}

export default withAuthentication(NavigationItems);