import React, { Component } from "react";

import EditableLabel from 'react-inline-editing';
import "./Editable.css";

class Editable extends  Component{
    constructor(props){
        super(props);

        this._handleFocus = this._handleFocus.bind(this);
        this._handleFocusOut = this._handleFocusOut.bind(this);
    }

    _handleFocus(text) {
        console.log('Focused with text: ' + text);
    }

    _handleFocusOut(text) {
        this.props.updateName(this.props.idNode, text);
    }

    render() {
        return (
            <div>
                <EditableLabel text={this.props.text ? this.props.text : ''}
                               labelClassName={this.props.labelClassName ? this.props.labelClassName : 'myLabelClass'}
                               inputClassName={this.props.inputClassName ? this.props.inputClassName : 'myInputClass'}
                               inputWidth={this.props.inputWidth ? this.props.inputWidth : '120px'}
                               inputHeight='25px'
                               onFocus={this._handleFocus}
                               onFocusOut={this._handleFocusOut}
                />
            </div>
        );
    }
}


export default Editable;