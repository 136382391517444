import React from 'react';


import { useTranslation } from 'react-i18next';


function UserList (props) {

    const { t, i18n } = useTranslation();

    return <table className="table">

        <tbody>
        <tr>
            <th scope={"col"}>{t('admComptes.email')}</th>
            <th scope={"col"}>{t('admComptes.username')}</th>
            <th scope={"col"}>{t('admComptes.role')}</th>
            <th scope={"col"}>{t('admComptes.edit')}</th>
        </tr>
        {props.users.map(user => (

            <tr  key={user.uid}>
                <th scope={"row"}>
                    {user.email}
                </th>
                <td>
                    {user.username}
                </td>
                <td>
                    {Object.keys(user.roles)[0]}
                </td>
                <td className="cursor" onClick={() => props.edit(user)}>
                    <i className="material-icons">edit</i>
                </td>
            </tr>
        ))}

        </tbody>
    </table>
};

export default UserList;