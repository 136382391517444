import React, { Component, useContext } from 'react';
import axios from '../../axios-base';
import Connexion from './Connexion/Connexion';
import Spinner from '../UI/Spinner/Spinner';
import './Connexions.css';
import * as Yup from 'yup';
import { TextField, Select } from 'mui-rff';
import CustomFieldArrayNoPopUp from '../Parametres/Form/CustomFieldArrayNoPopUp';
import withAuthorization from '../Session/withAuthorization/withAuthorization';
import * as ROUTES from "../../config/routes";
import { ConnexionsContext } from '../../context/ConnexionsContext';
import { Grid } from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

function FormFieldsHTTP() {
    const { t } = useTranslation();


    return <React.Fragment>
        <Grid item xs={12} key={1}>
            <TextField
                label={t("form.ID")}
                name="id"
            />
        </Grid>
        <Grid item xs={12} key={2}>
            <TextField
                label={t("form.ID_Client")}
                name="idClient"
            />
        </Grid>
        <Grid item xs={12} key={3}>
            <TextField
                label={t("form.nom")}
                name="name"
            />
        </Grid>
        <Grid item xs={12} key={4}>
            <Select
                label={t("form.flow_type")}
                name="flowType"
                data={[
                    { label: '', value: '' },
                    { label: t("form.codeAuth"), value: 'AuthorizationCode' },
                    { label: t('form.ClientCredentials'), value: 'ClientCredentials' },
                    { label: t('form.PasswordCredentials'), value: 'PasswordCredentials' },
                    { label: t('form.BasicAuth'), value: 'BasicAuth' }
                ]}
            />
        </Grid>
        <Grid item xs={12} key={5}>
            <TextField
                label={t("form.authorize_uri")}
                name="authorizeUri"
            />
        </Grid>
        <Grid item xs={12} key={6}>
            <TextField
                label="Token"
                name="tokenUri"
            />
        </Grid>
        <Grid item xs={12} key={7}>
            <TextField
                label={t("form.endpoint_url")}
                name="endpointUrl"
            />
        </Grid>
        <Grid item xs={12} key={8}>
            <TextField
                label="Scope"
                name="scope"
            />
        </Grid>
        <Grid item xs={12} key={9}>
            <TextField
                label={t("form.ID_Client")}
                name="clientId"
            />
        </Grid>
        <Grid item xs={12} key={10}>
            <TextField
                label={t("form.clientSecret")}
                name="clientSecret"
            />
        </Grid>
        <Grid item xs={12} key={11}>
            <CustomFieldArrayNoPopUp
                label={t("form.authorizeParameters")}
                name="authorizeParameters"
                addlabel="Add value"
            />
        </Grid>
        <Grid item xs={12} key={12}>
            <CustomFieldArrayNoPopUp
                label={t("form.accessTokenParameters")}
                name="accessTokenParameters"
                addlabel="Add value"
            />
        </Grid>
        <Grid item xs={12} key={13}>
            <CustomFieldArrayNoPopUp
                label={t("form.refreshTokenParameters")}
                name="refreshTokenParameters"
                addlabel="Add value"
            />
        </Grid>
        <Grid item xs={12} key={14}>
            <CustomFieldArrayNoPopUp
                label={t("form.customHeaders")}
                name="customHeaders"
                addlabel="Add value"
            />
        </Grid>
        <Grid item xs={12} key={15}>
            <TextField
                label={t("form.tokenPlacement")}
                name="tokenPlacement"
            />
        </Grid>
        <Grid item xs={12} key={16}>
            <TextField
                label={t("form.headerTokenName")}
                name="headerTokenName"
            />
        </Grid>
        <Grid item xs={12} key={17}>
            <TextField
                label={t("form.username")}
                name="username"
            />
        </Grid>
        <Grid item xs={12} key={18}>
            <TextField
                label={t("form.password")}
                name="password"
            />
        </Grid>
    </React.Fragment>;

    /*return [
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID")}
                    name="id"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID_Client")}
                    name="idClient"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.nom")}
                    name="name"
                />)
        },
        {
            size: 12,
            field: (
                <Select
                    label={t("form.flow_type")}
                    name="flow_type"
                    data={[
                        { label: '', value: '' },
                        { label: t("form.codeAuth"), value: 'AuthorizationCode' },
                        { label: t('form.username'), value: 'ClientCredentials' },
                        { label: t('form.password'), value: 'PasswordCredentials' },
                        { label: 'XML (text/xml)', value: 'text/xml' },
                        { label: 'HTML (text/html)', value: 'text/html' }
                    ]}
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.authorize_uri")}
                    name="authorizeUri"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Token"
                    name="tokenUri"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.endpoint_url")}
                    name="endpointUrl"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Scope"
                    name="scope"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID_Client")}
                    name="clientId"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.clientSecret")}
                    name="clientSecret"
                />)
        },
        {
            size: 12,
            field: (
                <CustomFieldArrayNoPopUp
                    label={t("form.authorizeParameters")}
                    name="authorizeParameters"
                    addlabel="Add value"
                />)
        },
        {
            size: 12,
            field: (
                <CustomFieldArrayNoPopUp
                    label={t("form.accessTokenParameters")}
                    name="accessTokenParameters"
                    addlabel="Add value"
                />)
        },
        {
            size: 12,
            field: (
                <CustomFieldArrayNoPopUp
                    label={t("form.refreshTokenParameters")}
                    name="refreshTokenParameters"
                    addlabel="Add value"
                />)
        },
        {
            size: 12,
            field: (
                <CustomFieldArrayNoPopUp
                    label={t("form.customHeaders")}
                    name="customHeaders"
                    addlabel="Add value"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.tokenPlacement")}
                    name="tokenPlacement"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.headerTokenName")}
                    name="headerTokenName"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.username")}
                    name="username"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.password")}
                    name="password"
                />)
        },
    ];*/
}

const schemaHTTP = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});

function FormFieldsFTP() {
    const { t, i18n } = useTranslation();

    return <React.Fragment>
        <Grid item xs={12} key={1}>
            <TextField
                label={t("form.ID")}
                name="id"
            />
        </Grid>
        <Grid item xs={12} key={2}>
            <TextField
                label={t("form.ID_Client")}
                name="idClient"
            />
        </Grid>
        <Grid item xs={12} key={3}>
            <TextField
                label={t("form.nom")}
                name="name"
            />
        </Grid>
        <Grid item xs={12} key={4}>
            <Select
                label={t("form.protocole")}
                name="protocole"
                data={[
                    { label: '', value: '' },
                    { label: 'FTP', value: 'FTP' },
                    { label: 'SFTP', value: 'SFTP' }
                ]}
            />
        </Grid>
        <Grid item xs={12} key={5}>
            <TextField
                label={t("form.transfertMode")}
                name="transfertmode"
            />
        </Grid>
        <Grid item xs={12} key={6}>
            <TextField
                label={t("form.host")}
                name="host"
            />
        </Grid>
        <Grid item xs={12} key={7}>
            <TextField
                label={t("form.port")}
                name="port"
            />
        </Grid>
        <Grid item xs={12} key={8}>
            <TextField
                label={t("form.username")}
                name="username"
            />
        </Grid>
        <Grid item xs={12} key={9}>
            <TextField
                label={t("form.password")}
                name="password"
            />
        </Grid>
        <Grid item xs={12} key={10}>
            <TextField
                label={t("form.TLSprotocole")}
                name="TLSprotocol"
            />
        </Grid>

    </React.Fragment>;

    /*return [
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID")}
                    name="id"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID_Client")}
                    name="idClient"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.nom")}
                    name="name"
                />)
        },
        {
            size: 12,
            field: (
                <Select
                    label={t("form.protocole")}
                    name="protocole"
                    data={[
                        { label: '', value: '' },
                        { label: 'FTP', value: 'FTP' },
                        { label: 'SFTP', value: 'SFTP' }
                    ]}
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.transfertMode")}
                    name="transfertmode"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.host")}
                    name="host"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.port")}
                    name="port"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.username")}
                    name="username"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.password")}
                    name="password"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.TLSprotocole")}
                    name="TLSprotocol"
                />)
        }
    ];*/
}
const schemaFTP = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
function FormFieldsMySQL() {
    const { t } = useTranslation();

    return <React.Fragment>
        <Grid item xs={12}>
            <TextField
                label={t("form.ID")}
                name="id"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.ID_Client")}
                name="idClient"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.nom")}
                name="name"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.host")}
                name="host"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.port")}
                name="port"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.dbname")}
                name="dbname"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.username")}
                name="username"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.password")}
                name="password"
            />
        </Grid>
    </React.Fragment>;


    /*return [
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID")}
                    name="id"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.ID_Client")}
                    name="idClient"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.nom")}
                    name="name"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.host")}
                    name="host"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.port")}
                    name="port"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.dbname")}
                    name="dbname"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.username")}
                    name="username"
                />)
        },
        {
            size: 12,
            field: (
                <TextField
                    label={t("form.password")}
                    name="password"
                />)
        }
    ];*/
}
const schemaMySQL = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});

function FormFieldsSMTP() {
    const { t } = useTranslation();

    return <React.Fragment>
        <Grid item xs={12}>
            <TextField
                label={t("form.ID")}
                name="id"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.ID_Client")}
                name="idClient"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.nom")}
                name="name"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.server")}
                name="server"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.port")}
                name="port"
            />
        </Grid>        
        <Grid item xs={12}>
            <TextField
                label={t("form.username")}
                name="username"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("form.password")}
                name="password"
            />
        </Grid>
    </React.Fragment>;
}

const schemaSMTP = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});

class Connexions extends Component {

    static contextType = ConnexionsContext;

    componentDidMount() {
        const clientId = this.props.client.id;
        if (!clientId)
            this.props.history.push(ROUTES.SIGN_IN);
        // axios.get('/getConnexions/' + clientId)
        //     .then(response => {
        //         this.context.setConnexionsList(response.data);
        //         console.log(response.data);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }

    updateConnexionHTTP = (data) => {
        axios.post('/updateConnexionHTTP/' + data.id, data)
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateConnexionFTP = (data) => {
        axios.post('/updateConnexionFTP/' + data.id, data)
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateConnexionMySQL = (data) => {
        axios.post('/updateConnexionMySQL/' + data.id, data)
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateConnexionSMTP = (data) => {
        axios.post('/updateConnexionSMTP/' + data.id, data)
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    createConnexions = (type) => {
        axios.post('/createConnexions/' + this.props.client.id, { 'toto': 'ututu' })
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    createConnexionsFTP = (type) => {
        axios.post('/createFTPConnexions/' + this.props.client.id, { 'toto': 'ututu' })
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    createConnexionsMySQL = (type) => {
        axios.post('/createMySQLConnexions/' + this.props.client.id, { 'toto': 'ututu' })
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    createConnexionsSMTP = (type) => {
        axios.post('/createSMTPConnexions/' + this.props.client.id, { 'toto': 'ututu' })
            .then(response => {
                this.context.setConnexionsList(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const { t } = this.props;
        let pageContent = null;

        if (this.context.connexionsList !== null && this.context.connexionsList !== undefined) {
            let HttpConnexions = null;
            if (this.context.connexionsList['http']) {
                HttpConnexions = Object.keys(this.context.connexionsList['http']).map(itKey => {
                    let connexion = this.context.connexionsList['http'][itKey];
                    return <Connexion
                        formFields={<FormFieldsHTTP />}
                        schema={schemaHTTP}
                        params={connexion}
                        key={itKey} idConnexion={itKey} name={connexion.name} updateConnexion={this.updateConnexionHTTP} />
                });
            };

            let FtpConnexions = null;
            if (this.context.connexionsList['ftp']) {
                FtpConnexions = Object.keys(this.context.connexionsList['ftp']).map(itKey => {
                    let connexion = this.context.connexionsList['ftp'][itKey];
                    return <Connexion
                        formFields={<FormFieldsFTP />}
                        schema={schemaFTP}
                        params={connexion}
                        key={itKey} idConnexion={itKey} name={connexion.name} updateConnexion={this.updateConnexionFTP} />
                });
            };

            let MySQLConnexions = null;
            if (this.context.connexionsList['mysql']) {
                MySQLConnexions = Object.keys(this.context.connexionsList['mysql']).map(itKey => {
                    let connexion = this.context.connexionsList['mysql'][itKey];
                    return <Connexion
                        formFields={<FormFieldsMySQL />}
                        schema={schemaMySQL}
                        params={connexion}
                        key={itKey} idConnexion={itKey} name={connexion.name} updateConnexion={this.updateConnexionMySQL} />
                });
            };

            let SMTPConnexions = null;
            if (this.context.connexionsList['smtp']) {
                SMTPConnexions = Object.keys(this.context.connexionsList['smtp']).map(itKey => {
                    let connexion = this.context.connexionsList['smtp'][itKey];
                    return <Connexion
                        formFields={<FormFieldsSMTP />}
                        schema={schemaSMTP}
                        params={connexion}
                        key={itKey} idConnexion={itKey} name={connexion.name} updateConnexion={this.updateConnexionSMTP} />
                });
            };

            pageContent = <div className="list-group list-group-item-action list-group-flush">
                {HttpConnexions}
                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend"
                    onClick={() => this.createConnexions('http')}>
                    <i className="material-icons">add</i> {t("connexion.ajoutHTTP")}</button>
                {FtpConnexions}
                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend"
                    onClick={() => this.createConnexionsFTP()}>
                    <i className="material-icons">add</i> {t("connexion.ajoutFTP")}</button>
                {MySQLConnexions}
                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend"
                    onClick={() => this.createConnexionsMySQL()}>
                    <i className="material-icons">add</i> {t("connexion.ajoutMySQL")}</button>
                {SMTPConnexions}
                <button type="button" className="btn list-group-item list-group-item-action input-group-prepend"
                    onClick={() => this.createConnexionsSMTP()}>
                    <i className="material-icons">add</i> {t("connexion.ajoutSMTP")}</button>
            </div>;
        }
        else {
            pageContent = <Spinner />;
        }

        return (
            <React.Fragment>
                <div className="jumbotron clients">
                    <h1 className="display-4">{t("connexion.titre")}</h1>
                    <hr className="my-4" />

                    {pageContent}

                </div>
            </React.Fragment>
        );
    }
};


const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(Connexions));