import React from 'react';
import { Link } from "react-router-dom";
import './NavigationItem.css';

const NavigationItem = (props) => {

    return (
        <React.Fragment>
        <li className="nav-item" onClick={props.clicked}>
            <Link className="nav-link" to={props.link}>
                <i className="material-icons">{props.icon}</i>
                <p>{props.name}</p>
            </Link>
        </li>
            <hr/>
        </React.Fragment>
    );
};

export default NavigationItem;