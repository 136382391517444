//config dev
// const config = {
//     apiKey: "AIzaSyAPU8EldXMxPF_BxknjhToBWD5I-qRKN18",
//     authDomain: "ofc-front-dev.firebaseapp.com",
//     databaseURL: "https://ofc-front-dev.firebaseio.com",
//     projectId: "ofc-front-dev",
//     storageBucket: "ofc-front-dev.appspot.com",
//     messagingSenderId: "958595400671",
//     appId: "1:958595400671:web:ba49cc92b2ac93babd3a6a",
//     measurementId: "G-W19LSHCVHP"
// };

//config demo
// const config = {
//     apiKey: "AIzaSyAf3kPmrOSrvJ1UuAE383JZLnNw735RQgk",
//     authDomain: "ofc-front-demo.firebaseapp.com",
//     databaseURL: "https://ofc-front-demo.firebaseio.com",
//     projectId: "ofc-front-demo",
//     storageBucket: "ofc-front-demo.appspot.com",
//     messagingSenderId: "1097649668482",
//     appId: "1:1097649668482:web:41969e969381dd7f178f9b"
//   };

//config prod
const config = {
    apiKey: "AIzaSyCMngdpdY5PAbi6o5tJCsji7-NkCoj4Vio",
    authDomain: "ofc-front.firebaseapp.com",
    databaseURL: "https://ofc-front.firebaseio.com",
    projectId: "ofc-front",
    storageBucket: "ofc-front.appspot.com",
    messagingSenderId: "836990547495",
    appId: "1:836990547495:web:4127fe88bdb98203a28002",
    measurementId: "G-BVJELVJV2G"
  };

export default config;