import React, {Component} from 'react';

import { withRouter } from 'react-router-dom';
import { withFirebase } from './../Firebase';
import * as ROUTES from './../../config/routes';
import * as ROLES from './../../config/roles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logos from '../../assets/logos';
import './Login.css';
import withAuthorization from "../Session/withAuthorization/withAuthorization";

import { withTranslation } from 'react-i18next';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.masolutiondev.fr/">
                MaSolution Dev
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const INITIAL_STATE = {
    email: '',
    password: '',
    isAdmin: false,
    error: null,
};


class LoginFormBase extends Component  {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onSubmit = event => {
        const { email, password } = this.state;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then((authUser) => {
                const username = email;
                let roles = [];
                roles[ROLES.ADMIN] = ROLES.ADMIN;
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.ADMIN_CLIENTS);

                // Create a user in your Firebase realtime database
                /*this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username,
                        email,
                        roles
                    });*/

            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;
        const {t} = this.props;
        const isInvalid = password === '' || email === '';

        const classes = makeStyles((theme) => ({
            paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            avatar: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }));

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <img src={Logos.ofBanner} alt={"OpenFlux Cloud"} width={"500px"}/>
                </Grid>
                <div className={"login-title"}>
                    <Avatar className={classes.avatar}>
                        <i className="material-icons">
                            lock
                        </i>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("login.titre")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("form.email")}
                            name="email"
                            value={email}
                            autoComplete="email"
                            autoFocus
                            onChange={this.onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={password}
                            label={t("form.password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.onChange}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label={t("login.souvenirDeMoi")}
                        />
                        {error && <div className="alert alert-danger" role="alert">{error.message}</div>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.onSubmit}
                        >
                            {t("login.connect")}
                        </Button>

                        <Grid container>
                            <Grid item xs>
                                <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                                    {t("login.forgetPass")}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Container>
        );
    }
};

const condition = authUser =>
    !(authUser && !!authUser.roles[ROLES.ADMIN]);

const Login = withAuthorization(condition, ROUTES.ADMIN_CLIENTS)(withTranslation()(LoginFormBase));

export default Login;