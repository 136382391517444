import React from 'react';
import * as Yup from 'yup';
import { makeRequired, Select, Checkboxes } from 'mui-rff';
import CustomTextField from '../CustomTextField';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuFTPschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const required = makeRequired(TuFTPschema);

function TuFTP (props) {
    const {t} = useTranslation('diagram');

    const helperText = t("param.requis");

    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let FTPConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={FTPConnexions}
                />
            </Grid>;
    }

    return (
        <React.Fragment>

            {connexionField}

            <Grid item xs={12} key={1}>
                <Select
                    label={t('param.transfertdirection')}
                    name="transfertdirection"
                    data={[
                        { label: 'upload', value: 'upload' },
                        { label: 'download', value: 'download' },
                        { label: 'directorylist', value: 'directorylist' },
                        { label: 'rename', value: 'rename' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.filename")}
                    name="filename"
                    id="filename"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <CustomTextField
                    label={t("param.filedatas")}
                    name="filedatas"
                    id="filedatas"
                />
            </Grid>
            <Grid item xs={12} key={4}>
                <Checkboxes
                    // label="is data a file"
                    name="isfiledatas"
                    // required={required.best}
                    data={{ label: t('param.isfile'), value: true }}
                    helperText={helperText}
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <Checkboxes
                    // label="is data a file"
                    name="getdirectorydatas"
                    // required={required.best}
                    data={{ label: t('param.getdirectorydatas'), value: true }}
                    helperText={helperText}
                />
            </Grid>

        </React.Fragment>
    );
}

export default TuFTP;