import React from 'react';
import * as Yup from 'yup';

import { Grid } from '@material-ui/core';
import { TextField, makeRequired, Select, Checkboxes } from 'mui-rff';

import { useTranslation } from 'react-i18next';

import CustomTextField from '../CustomTextField';
import CustomFieldArray from '../CustomFieldArray';
import Client from "../../../Clients/Client/Client";

export const TuHTTPschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const helperText = '* Requis';
const required = makeRequired(TuHTTPschema);

function TuHTTP (props) {

    const {t} = useTranslation('diagram');

    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let HttpConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={HttpConnexions}
                />
            </Grid>;
    }
    let connexionsmtpField = null;
    if (props.connexionDataSmtp) {
        console.log(props.connexionDataSmtp);
        let SmtpConnexions = props.connexionDataSmtp.map(itKey => {
            let connexion = itKey;            
            return { label: connexion.name, value: connexion.id };
        });
        //HttpConnexions.unshift({ label: '', value: '' });
        connexionsmtpField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.smtpconnexion_id")}
                    name="smtpconnexion_id"
                    data={SmtpConnexions}
                />
            </Grid>;
    }
    return (
        <React.Fragment>
            {connexionField}

            <Grid item xs={12} key={1}>
                <CustomTextField
                    label={t('param.url')}
                    name="URL"
                    id="URL"
                />
            </Grid>

            <Grid item xs={12} key={2}>
                <Select
                    label={t('param.method')}
                    name="method"
                    data={[
                        { label: '', value: '' },
                        { label: 'GET', value: 'GET' },
                        { label: 'POST', value: 'POST' },
                        { label: 'PUT', value: 'PUT' },
                        { label: 'DELETE', value: 'DELETE' },
                        { label: 'PATCH', value: 'PATCH' },
                        { label: 'HEAD', value: 'HEAD' }
                    ]}
                />
            </Grid>

            <Grid item xs={12} key={3}>
                <Select
                    label={t('param.body_type')}
                    name="body_type"
                    data={[
                        { label: '', value: '' },
                        { label: 'Raw', value: 'Raw' },
                        { label: 'Application/x-www-form-urlencoded', value: 'x_www_form_urlencoded' },
                        { label: 'Multipart/form-data', value: 'multipart_form_data' }
                    ]}
                />
            </Grid>

            <Grid item xs={12} key={4}>
                <Select
                    label={t('param.content_type')}
                    name="content_type"
                    data={[
                        { label: '', value: '' },
                        { label: 'Text (text/plain)', value: 'text/plain' },
                        { label: 'JSON (application/json)', value: 'application/json' },
                        { label: 'XML (application/xml)', value: 'application/xml' },
                        { label: 'XML (text/xml)', value: 'text/xml' },
                        { label: 'HTML (text/html)', value: 'text/html' }
                    ]}
                />
            </Grid>

            <Grid item xs={12} key={5}>
                <CustomTextField
                    label={t('param.request_content')}
                    name="request_content"
                    id="request_content"
                />
            </Grid>

            <Grid item xs={12} key={6}>
                <CustomFieldArray
                    label={t('param.form_values')}
                    name="form_values"
                    addlabel={t("param.addvalue")}
                />
            </Grid>

            <Grid item xs={12} key={7}>
                <CustomFieldArray
                    label={t('param.query_string')}
                    name="query_string"
                    addlabel={t("param.addparam")}
                />
            </Grid>

            <Grid item xs={12} key={8}>
                <CustomFieldArray
                    label={t('param.headers')}
                    name="headers"
                    addlabel={t("param.addheader")}
                />
            </Grid>

            <Grid item xs={12} key={9}>
                <TextField
                    label={t('param.timeout')}
                    name="timeout"
                />
            </Grid>

            <Grid item xs={12} key={10}>
                <Checkboxes
                    //label={t('param.okcookie')}
                    name="okcookie"
                    data={{ label: t('param.okcookie'), value: true }}
                />
            </Grid>

            <Grid item xs={12} key={11}>
                <TextField
                    label={t('param.response_type')}
                    name="response_type"
                />
            </Grid>
            
            <Grid item xs={12} key={5}>
                <Checkboxes
                    // label="is data a file"
                    name="okmailonerror"
                    // required={required.best}
                    data={{ label: t('param.okmailonerror'), value: true }}
                    helperText={helperText}
                />
            </Grid>
            {connexionsmtpField}
            <Grid item xs={12} key={5}>
                <CustomTextField
                    label={t('param.subject')}
                    name="subject"
                    id="subject"
                />
            </Grid>        
            <Grid item xs={12} key={5}>
                <CustomTextField
                    label={t('param.mailfrom')}
                    name="mailfrom"
                    id="mailfrom"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <CustomTextField
                    label={t('param.mailto')}
                    name="mailto"
                    id="mailto"
                />
            </Grid>
        </React.Fragment>
    );
}

export default TuHTTP;
/*export const formFields = [
    {
        size: 12,
        field: (
            <CustomTextField
                label="URL"
                name="URL"
                id="URL"
            />)
    },
    {
        size: 12,
        field: (
            <CustomSelect
                label="Méthode"
                name="method"
                data={[
                    { label: '', value: '' },
                    { label: 'GET', value: 'GET' },
                    { label: 'POST', value: 'POST' },
                    { label: 'PUT', value: 'PUT' },
                    { label: 'DELETE', value: 'DELETE' },
                    { label: 'PATCH', value: 'PATCH' },
                    { label: 'HEAD', value: 'HEAD' }
                ]}
            />)
    },
    {
        size: 12,
        field: (
            <Select
                label="Type body"
                name="body_type"
                data={[
                    { label: '', value: '' },
                    { label: 'Raw', value: 'Raw' },
                    { label: 'Application/x-www-form-urlencoded', value: 'x_www_form_urlencoded' },
                    { label: 'Multipart/form-data', value: 'multipart_form_data' }
                ]}
            />)
    },
    {
        size: 12,
        field: (
            <CustomSelect
                label="Type de contenu"
                name="content_type"
                data={[
                    { label: '', value: '' },
                    { label: 'Text (text/plain)', value: 'text/plain' },
                    { label: 'JSON (application/json)', value: 'application/json' },
                    { label: 'XML (application/xml)', value: 'application/xml' },
                    { label: 'XML (text/xml)', value: 'text/xml' },
                    { label: 'HTML (text/html)', value: 'text/html' }
                ]}
            />)
    },
    {
        size: 12,
        field: (<CustomTextField
            label="Contenu de la requête"
            name="request_content"
            id="request_content"
        />)
    },
    {
        size: 12,
        field: (
            <CustomFieldArray
                label="Valeurs du formulaire"
                name="form_values"
                addlabel="Add value"
            />)
    },    
    {
        size: 12,
        field: (
            <CustomFieldArray
                label="Query string"
                name="query_string"
                addlabel="Add parameter"
            />)
    },
    {
        size: 12,
        field: (
            <CustomFieldArray
                label="Headers"
                name="headers"
                addlabel="Add a header"
            />)
    },
    {
        size: 12,
        field: (<TextField
            label="Timeout"
            name="timeout"
        />)
    },
    {
        size: 12,
        field: (<TextField
            label="Ok cookie"
            name="okcookie"
        />)
    },
    {
        size: 12,
        field: (<TextField
            label="Type de réponse"
            name="response_type"
        />)
    },
    // {
    //     size: 12,
    //     field: (<TextField
    //         label="connexion_id"
    //         name="connexion_id"
    //     />)
    // }
];*/