import React, { useContext, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Popup from "reactjs-popup";
import JSONEditorReact from '../JsonEditor/JSONEditorReact';
import { Field } from 'react-final-form';


const CustomJsonField = function (props) {
    const style = { overflow: 'auto', width: '1000px', height: '500px' };
    const overlaystyle = {background:'rgba(0, 0, 0, 0)' };    
   
    const afterClose = () => {
        var e = window.event;
        var xPos = e.clientX;
        var yPos = e.clientY;
        setTimeout(() => {
            document.elementFromPoint(xPos,yPos).focus();
            document.elementFromPoint(xPos,yPos).click();
        }, 50);
    };
    
    const _onChange = ({
        target: { value },
    }) => props.onChange(value === '' ? props.options.emptyValue : value);
    // const _onBlur = ({ target: { value } }) => props.onBlur(props.id, value);
    // const _onFocus = ({ target: { value }, }) => props.onFocus(props.id, value);

    return (
        <React.Fragment>
             
        <Field name={props.name}>
            {fieldprops => {
                props = { ...props, ...fieldprops };                
                return (
                    <Popup
                        modal={true}
                        position="left top"
                        contentStyle={style}
                        overlayStyle={overlaystyle}
                        //onClose={() => {document.getElementById(props.id).focus()}}
                        onClose={() => afterClose()}

                        trigger={
                            <FormControl
                                fullWidth={true}
                                //error={!!rawErrors}
                                required={props.required}
                            >
                                <TextField
                                    id={props.id}
                                    name={props.input.name}
                                    value={props.input.value}
                                    // label={t(props.label) || t(props.schema.title)}
                                    onChange={props.input.onChange}
                                    onBlur={props.input.onBlur}
                                    onFocus={props.input.onFocus}
                                    placeholder={props.placeholder}
                                    disabled={props.disabled || props.readonly}
                                    required={props.required}
                                    autoFocus={props.autofocus}
                                    multiline={props.multiline}
                                    rows={props.rows}
                                />
                            </FormControl>
                        }>
                               
                                <JSONEditorReact
                                    mainMenuBar={true}
                                    navigationBar={true}
                                    search={true}
                                    text={props.input.value!=''?props.input.value:null}
                                      modes={['tree', 'view', 'form', 'code', 'text']}
                                    indentation={4}
                                    modetree={true}
                                />
                                

                    </Popup>
                )
            }
            }
        </Field>
        </React.Fragment>
    );
}

export default CustomJsonField;