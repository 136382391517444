import React from 'react';
import './Menu.css';
import NavigationItems from './../NavigationItems/NavigationItems';


const menu = (props) => {
    return (
        <div className={"sidebar sidebar-" + props.theme} data-background-color="black">
            <NavigationItems buttonClics={props.buttonClics} theme={props.theme} profil={props.profil}/>
        </div>
    );
};

export default React.memo(menu);