import React from "react";

import { useTranslation } from 'react-i18next';

const CatalogTypesNonTranslated = () => {

    let catalogTypes = [];
    catalogTypes[1] = { 'id': 1, 'code': 'setvariable', 'name': "tu.setvariable" };
    catalogTypes[2] = { 'id': 2, 'code': 'getvariable', 'name': "tu.getvariable" };
    catalogTypes[3] = { 'id': 3, 'code': 'oauthhttp', 'name': "tu.oauthhttp" };
    catalogTypes[4] = { 'id': 4, 'code': 'createdata', 'name': "tu.createdata" };
    catalogTypes[5] = { 'id': 5, 'code': 'parsejson', 'name': "tu.parsejson" };
    catalogTypes[6] = { 'id': 6, 'code': 'createjson', 'name': "tu.createjson" };
    catalogTypes[7] = { 'id': 7, 'code': 'parsexml', 'name': "tu.parsexml" };
    catalogTypes[8] = { 'id': 8, 'code': 'createxml', 'name': "tu.createxml" };
    catalogTypes[9] = { 'id': 9, 'code': 'createtxt', 'name': "tu.createtxt" };
    catalogTypes[10] = { 'id': 10, 'code': 'httpsimple', 'name': "tu.httpsimple" };
    catalogTypes[11] = { 'id': 11, 'code': 'foreach', 'name': "tu.foreach" };
    catalogTypes[12] = { 'id': 12, 'code': 'array', 'name': "tu.array" };
    catalogTypes[13] = { 'id': 13, 'code': 'parsecsv', 'name': "tu.parsecsv" };
    catalogTypes[14] = { 'id': 14, 'code': 'articleshopify', 'name': "tu.articleshopify" };
    catalogTypes[15] = { 'id': 15, 'code': 'entityxrpflex', 'name': "tu.entityxrpflex" };
    catalogTypes[16] = { 'id': 16, 'code': 'ftp', 'name': "tu.ftp" };
    catalogTypes[17] = { 'id': 17, 'code': 'mysql', 'name': "tu.mysql" };
    catalogTypes[18] = { 'id': 18, 'code': 'email', 'name': "tu.email" };
    catalogTypes[19] = { 'id': 19, 'code': 'hubspot', 'name': "tu.hubspot" };
    catalogTypes[20] = { 'id': 20, 'code': 'cegiddataservice', 'name': "tu.cegiddataservice" };
    catalogTypes[21] = { 'id': 21, 'code': 'xrpflexputcde', 'name': "tu.xrpflexputcde" };

    return (catalogTypes);
};

function CatalogTypesTranslated() {
    const { t } = useTranslation('diagram');
    //const {t} = props;

    let catalofTypes = CatalogTypesNonTranslated();
    catalofTypes.forEach(element =>
        element.name = t(element.name)
    );

    return catalofTypes;

    /*let catalogTypes = [];
    catalogTypes[1] = {'id':1,'code':'setvariable','name':t("tu.setvariable")};
    catalogTypes[2] = {'id':2,'code':'getvariable','name':t("tu.getvariable")};
    catalogTypes[3] = {'id':3,'code':'oauthhttp','name':t("tu.oauthhttp")};
    catalogTypes[4] = {'id':4,'code':'createdata','name':t("tu.createdata")};
    catalogTypes[5] = {'id':5,'code':'parsejson','name':t("tu.parsejson")};
    catalogTypes[6] = {'id':6,'code':'createjson','name':t("tu.createjson")};
    catalogTypes[7] = {'id':7,'code':'parsexml','name':t("tu.parsexml")};
    catalogTypes[8] = {'id':8,'code':'createxml','name':t("tu.createxml")};
    catalogTypes[9] = {'id':9,'code':'createtxt','name':t("tu.createtxt")};
    catalogTypes[10] = {'id':10,'code':'httpsimple','name':t("tu.httpsimple")};
    catalogTypes[11] = {'id':11,'code':'foreach','name':t("tu.foreach")};
    catalogTypes[12] = {'id':12,'code':'array','name':t("tu.array")};
    catalogTypes[13] = {'id':13,'code':'parsecsv','name':t("tu.parsecsv")};
    catalogTypes[14] = {'id':14,'code':'articleshopify','name':"tu.articleshopify"};
    catalogTypes[15] = {'id':15,'code':'entityxrpflex','name':"tu.entityxrpflex"};
    catalogTypes[16] = {'id':16,'code':'ftp','name':t("tu.ftp")};
    catalogTypes[17] = {'id':17,'code':'mysql','name':t("tu.mysql")};
    catalogTypes[18] = {'id':18,'code':'email','name':t("tu.email")};
    catalogTypes[19] = {'id':19,'code':'hubspot','name':t("tu.hubspot")};
    // catalogTypes[19] = {'id':19,'code':'hubspotcompanies','name':t("tu.hubspotcompanies")};

    return (catalogTypes);*/
}
export default CatalogTypesTranslated;



function CatalogTUTypesAll() {

    const listTU = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 18,21];

    let catalogTUTypes = [];
    const cat = CatalogTypesNonTranslated();
    cat.map(element => {
        console.log(element.id);
        if (listTU.includes(element.id))
            catalogTUTypes[element.id] = element;
    });

    return (catalogTUTypes);
}

function CatalogAppTypesAll() {
    //const {t} = useTranslation('diagram');

    const listApp = [14, 15, 19];

    let catalogAppTypes = [];
    const cat = CatalogTypesNonTranslated();
    cat.map(element => {
        console.log(element.id);
        if (listApp.includes(element.id))
            catalogAppTypes[element.id] = element;
    });

    console.log(catalogAppTypes);

    /*
    catalogAppTypes[14] = {'id':14,'code':'articleshopify','name':"tu.articleshopify"};
    catalogAppTypes[15] = {'id':15,'code':'entityxrpflex','name':"tu.entityxrpflex"};
    catalogAppTypes[19] = {'id':19,'code':'hubspot','name':"tu.hubspot"};
    catalogAppTypes[200] = {'id':200,'code':'exact','name':"tu.exact"};*/
    return (catalogAppTypes);
}

function CatalogAppTypesNonTranslated() {

    let catalogAppTypes = [];
    const allApps = CatalogAppTypesAll();

    catalogAppTypes[101] = { 'type': 'tu.hubspot', 'code': 'hubspot', 'appz': [allApps[19], allApps[19]] };
    catalogAppTypes[102] = { 'type': 'tu.entityxrpflex', 'code': 'entityxrpflex', 'appz': [{ 'id': 15, 'code': 'entityxrpflex', 'name': "tu.entityxrpflex" }, { 'id': 15, 'code': 'entityxrpflex', 'name': "tu.entityxrpflex" }] };
    catalogAppTypes[103] = { 'type': 'tu.articleshopify', 'code': 'articleshopify', 'appz': [{ 'id': 14, 'code': 'articleshopify', 'name': "tu.articleshopify" }, { 'id': 14, 'code': 'articleshopify', 'name': "tu.articleshopify" }] };
    catalogAppTypes[104] = { 'type': 'tu.exact', 'code': 'exact', 'appz': [{ 'id': 20, 'code': 'exact', 'name': "tu.exact" }, { 'id': 20, 'code': 'exact', 'name': "tu.exact" }] };

    /*catalogAppTypes['app.hubspot'] = [{'id':19,'code':'hubspot','name':"tu.hubspot"}, {'id':20,'code':'hubspot','name':"tu.hubspot"}];
    catalogAppTypes['tu.entityxrpflex'] = [{'id':15,'code':'entityxrpflex','name':"tu.entityxrpflex"}, {'id':15,'code':'entityxrpflex','name':"tu.entityxrpflex"}];
    catalogAppTypes['tu.articleshopify'] = [{'id':14,'code':'articleshopify','name':"tu.articleshopify"}, {'id':14,'code':'articleshopify','name':"tu.articleshopify"}];
    catalogAppTypes['app.hubspot'] = [{'id':20,'code':'exact','name':"tu.exact"}, {'id':20,'code':'exact','name':"tu.exact"}];


    catalogAppTypes[110] = {'id': 110, 'code': 'logicielfinancier', 'name': "app.logicielfinancier"};
    catalogAppTypes[111] = {'id': 111, 'code': 'API', 'name': "app.api"};
    catalogAppTypes[112] = {'id': 112, 'code': 'baseinformations', 'name': "app.baseinformations"};
    catalogAppTypes[113] = {'id': 113, 'code': 'ERP', 'name': "app.erp"};
    catalogAppTypes[114] = {'id': 114, 'code': 'chatbot', 'name': "app.chatbot"};
    catalogAppTypes[115] = {'id': 115, 'code': 'IOT', 'name': "app.IOT"};
    catalogAppTypes[116] = {'id': 116, 'code': 'edoc', 'name': "app.edoc"};
    catalogAppTypes[117] = {'id': 117, 'code': 'ecommerce', 'name': "app.ecommerce"};

    catalogAppTypes[14] = {'id':14,'code':'articleshopify','name':"tu.articleshopify"};
    catalogAppTypes[15] = {'id':15,'code':'entityxrpflex','name':"tu.entityxrpflex"};
    catalogAppTypes[19] = {'id':19,'code':'hubspot','name':"tu.hubspot"};
    catalogAppTypes[20] = {'id':20,'code':'exact','name':"tu.exact"};*/
    return (catalogAppTypes);
}

export { CatalogTUTypesAll, CatalogTypesNonTranslated, CatalogAppTypesNonTranslated };