import * as React from 'react';
import { OFCNodeModel } from './OFCNodeModel';
import { OFCNodeWidget } from './OFCNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

export class OFCNodeFactory extends AbstractReactFactory {
	constructor() {
		super('ofc-node');
	}

	generateModel(event) {
		return new OFCNodeModel();
	}

	generateReactWidget(event) {
		return <OFCNodeWidget engine={this.engine} node={event.model} />;
	}
}

