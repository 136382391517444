import React from 'react';
import './Logo.css';
import OFLogo from '../../assets/logos/openflux4.png';

const logo = (props) => {
    return (
    <div className="Logo">
        <a href="/"><img src={OFLogo} alt="OFC"/></a>
    </div>
    );
};

export default logo;