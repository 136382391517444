import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import data from "../../../assets/node-icons";

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function AppCollapse(props) {
    const classes = useStyles();
    const {t} = useTranslation('diagram');
    const [open, setOpen] = React.useState(false);

    const onClickMethod = (NodeId) => {

        console.log(NodeId);
        console.log(props);

        props.createNode(NodeId);

        if(props.closePopup) {
            props.closePopup();
        }
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const tuImg = data[props.data.code];
    return (
        <React.Fragment>
            <ListItem button onClick={() => handleClick()}>
                <ListItemIcon>
                    <div className="tu-item col-12"><img className={"tuIcon"} src={tuImg} /></div>
                </ListItemIcon>
                <ListItemText primary={t(props.data.type)} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.data.appz.map(itKeyApp => {
                        console.log(itKeyApp);
                        const tuImgApp = data[itKeyApp.code];

                        return <ListItem button className={classes.nested} onClick={() => onClickMethod(itKeyApp.id)}>
                            <ListItemIcon>
                                <div className="tu-item col-12" key={itKeyApp.id}><img className={"tuIcon"} src={tuImgApp} /></div>
                            </ListItemIcon>
                            <ListItemText primary={t(itKeyApp.name)} />
                        </ListItem>;
                    })}
                </List>
            </Collapse>

        </React.Fragment>
    );
}
