export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN_CLIENTS = '/admin';
export const ADMIN_ACCOUNTS = '/admin-accounts';
export const ADMIN_ACCOUNT = '/admin-account';
export const PASSWORD_FORGET = '/pw-forget';
export const DIAGRAM = '/diagram';
export const SCENARIOS = '/flux';
export const CONNEXIONS = '/connexions';