import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../UI/Modal/Modal';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../config/routes';
import * as ROLES from '../../config/roles';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';



const INITIAL_STATE = {
    username: '',
    email: '',
    roles : [],
    error: null,
};

class EditAccountFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...props.userInfo };
    }

    onSubmit = event => {
        const { uid, email,  username, roles } = this.state;
        this.props.firebase
            .user(uid)
            .set({
                email,
                roles,
                username
            })
            .then(() => {
            })
            .catch(error => {
                this.setState({ error });
            });


        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeSelect = event => {
        let roles = [];
        roles[event.target.value] = event.target.value;
        this.setState({ roles: roles });
    };

    render() {
        const {
            username,
            email,
            roles,
            error
        } = this.state;
        const { t } = this.props;

        const isInvalid =
            email === '' ||
            username === '';

        //const roleValue = roles ? roles.map(role => {return role}) : ROLES.CLIENT ;
        const roleValue = roles ? Object.keys(roles)[0] : ROLES.CLIENT ;

        return (
            <Modal show={true}>
                <form onSubmit={this.onSubmit}>
                    <input
                        name="username"
                        value={username}
                        onChange={this.onChange}
                        type="text"
                        placeholder={t("admComptes.username")}
                    />
                    <input
                        name="email"
                        value={email}
                        disabled={true}
                        type="text"
                        placeholder={t("admComptes.email")}
                    />
                    <FormControl>
                        <InputLabel id="role-simple-select-label">{t("admComptes.role")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={'roles'}
                            value={roleValue}
                            onChange={this.onChangeSelect}
                        >
                            <MenuItem value={ROLES.CLIENT}>{ROLES.CLIENT}</MenuItem>
                            <MenuItem value={ROLES.USER}>{ROLES.USER}</MenuItem>
                            <MenuItem value={ROLES.ADMIN}>{ROLES.ADMIN}</MenuItem>

                        </Select>)
                    </FormControl>
                    <button disabled={isInvalid} type="submit">
                        {t("admComptes.update")}
                    </button>

                    {error && <p>{error.message}</p>}
                </form>
            </Modal>
        );
    }
}


const TranslatedEditAccountFormBase = withTranslation()(EditAccountFormBase);

const EditAccountPage = withRouter(withFirebase(TranslatedEditAccountFormBase));

export default EditAccountPage;