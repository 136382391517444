import { DefaultLinkModel } from '@projectstorm/react-diagrams';
import { EditableLabelModel } from '../OFCLabel/EditableLabelModel';

export default class OFCLinkModel extends DefaultLinkModel {
    constructor() {
        super({
            type: 'ofclink', // <-- here we give it a new type
            //width: 2 // we specifically want this to also be width 10

        });
        this.addLabel(new EditableLabelModel({
            value: 'Hello, I am label!'
        }));
    }
}