import * as React from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import './OFCNodeWidget.css';
import data from '../../../assets/node-icons';
import Types, {typesLoaded, CatalogTypesNonTranslated} from '../CatalogTypesTranslated';
//import ReadTypeTU from "../ReadTypeTU";
import Editable from "../../../components/Editable/Editable";
import Tooltip from '@material-ui/core/Tooltip';
import {TranslationContext} from "../../../context/TranslationContext";

//import { withTranslation } from 'react-i18next';


export class OFCNodeWidget extends React.Component {

    static contextType = TranslationContext;

	render() {
		//const types = CatalogTypesNonTranslated();
        //this.context.setTranslations();
		const types = this.context.types;

        const type = (this.props.node.options.typeTU in types) ? types[this.props.node.options.typeTU] : null;

        //const type = ReadTypeTU(this.props.node.options.typeTU);

        let divStyle = {
            backgroundImage: 'url(' + data[type['code']] + ')'
        };

        return (
			<React.Fragment>
			<div className="custom-node">

				<PortWidget engine={this.props.engine} port={this.props.node.getPort('in')}>
					<div className="circle-port" />
				</PortWidget>
				<div className={"custom-node-container"}>
					<div className="custom-node-image" style={divStyle}  onClick={this.props.node.options.getpos}/>

					<div className="btn-group btn-group-sm node-toolbox">
						<Tooltip title={"Paramètres"}><button type="button" className="btn btn-light node-toolbox-button" onClick={() => this.props.node.options.fctparam(
							type['code'],
                            this.props.node.options.name,
							this.props.node.options.params,
							this.props.node.options.schema,
							this.props.node.options.id,
							this.props.node.options.id_node)}>
							<i className="material-icons">build</i>
						</button></Tooltip>

                        <Tooltip title={"Exécuter le traitement"}>
							<button type="button" className="btn btn-light node-toolbox-button" onClick={() => this.props.node.options.fctexecute(this.props.node.options.id_node)}>
                            <i className="material-icons">play_arrow</i>
							</button>
						</Tooltip>
                        <Tooltip title={"Supprimer"}>
						<button type="button" className="btn btn-light node-toolbox-button" onClick={this.props.node.options.removeModel}>
							<i className="material-icons">delete_forever</i>
						</button>
						</Tooltip>
					</div>
                </div>
				<PortWidget engine={this.props.engine} port={this.props.node.getPort('out')}>
					<div className="circle-port" />
				</PortWidget>
			</div>
				<div className={"row"}>
					<div className={"order-info order-info-label"}>{this.props.node.options.id_node}</div>

				<div className="cn-name">
                    <Editable text={this.props.node.options.name} idNode={this.props.node.options.id} updateName={this.props.node.options.updateName}
                    >
                    </Editable>

                </div>

                </div>
			</React.Fragment>
		);
	}
}