import React from 'react';

import { withRouter } from 'react-router-dom';
//import classes from './Layout.css';
//import Diagram from '../Diagram/Diagram.js';
import Menu from '../Navigation/Menu/Menu';
import * as ROUTES from "../../config/routes";
//import Parametres from '../Parametres/Parametres';
import StructureProvider from '../../components/Parametres/Structure/StructureContext';

const layout = (props) => {
    return (
        <StructureProvider >
            <React.Fragment>
                <Menu buttonClics={props.buttonClics} theme={props.theme} profil={props.profil}/>
                {props.children}
            </React.Fragment>
        </StructureProvider>
    );
};

export default withRouter(layout);