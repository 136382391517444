import React from 'react';
import * as Yup from 'yup';
import {TextField, makeRequired} from 'mui-rff';
import CustomTextField from '../CustomTextField';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export const TuTextschema = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const helperText = '* Requisd';
const required = makeRequired(TuTextschema);
function TuText() {
    const {t} = useTranslation('diagram');

    return (

        <React.Fragment>

            <Grid item xs={12} key={1}>
                <CustomTextField
                    label={t("param.datas_source")}
                    name="datas_source"
                    id="datas_source"
                    required={required.datas}
                    // helperText={helperText}
                />
            </Grid>

            <Grid item xs={12} key={2}>
                <TextField
                    label={t("param.text_format")}
                    name="text_format"
                />
            </Grid>

        </React.Fragment>
    );
}

export default TuText;