import React, { createContext, useState} from 'react';
import typesTranslated from '../components/Diagram/CatalogTypesTranslated';

export const TranslationContext = createContext({
        translations: null,
        setTranslations: () => {return typesTranslated()}
});

/*export function TranslationContext () {
    const typesT = types();
    return createContext({
        translations: typesT,
        setTranslations: () => {  }
    });
}*/

const TranslationsProvider = props => {
    const [translations, setTranslations] = useState({});
    const types = typesTranslated();
    return (
        /**
         * la propriété value est très importante ici, elle rend ici
         * le contenu du state disponible aux `Consumers` de l'application
         */
        <TranslationContext.Provider value={{ types, setTranslations}}>
            {props.children}
        </TranslationContext.Provider>
    );
};

export default TranslationsProvider;

