import React, { useState } from 'react';
import * as Yup from 'yup';
import { Select, makeRequired, Checkboxes } from 'mui-rff';
import CustomTextField from '../../CustomTextField';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import Condition from '../../Condition';

export const TuHubspotschemaCompanies = Yup.object().shape({
    // available: Yup.boolean()
    //     .oneOf([true], 'We are not available!')
    //     .required(),
    // hello: Yup.string(),
});
const helperText = '* Requis';
const required = makeRequired(TuHubspotschemaCompanies);

let count = 0;
// let checkboxData = [
//     { label: 'Ack', value: 'ack' },
//     { label: 'Bar', value: 'bar' },
//     { label: 'Foo', value: 'foo' },
// ];

function TuHubspotCompanies(props) {
    const [checkboxData, setcheckboxData] = useState([
        { label: 'Ack', value: 'ack' },
        { label: 'Bar', value: 'bar' },
        { label: 'Foo', value: 'foo' },
    ]);
    
    const { t } = useTranslation('diagram');
    let connexionField = null;
    if (props.connexionData) {
        console.log(props.connexionData);
        let HubspotConnexions = props.connexionData.map(itKey => {
            let connexion = itKey;
            console.log(connexion);
            return { label: connexion.name, value: connexion.id };
        });

        connexionField =
            <Grid item xs={12} key={0}>
                <Select
                    label={t("param.connexion_id")}
                    name="connexion_id"
                    data={HubspotConnexions}
                />
            </Grid>;
    }

    return (
        <React.Fragment>

            {connexionField}

            <Grid item xs={12} key={1}>
                <CustomTextField
                    label={t("param.hubspotapikey")}
                    name="apikey"
                    id="apikey"
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.hubspotmaxrows")}
                    name="maxrows"
                    id="maxrows"
                />
            </Grid>
            <Grid item xs={12} key={3}>
                <Select
                    label={t("param.hubspotendpoint")}
                    name="endpoint"
                    data={[
                        { label: t("param.hubspotcompanies"), value: 'companies' },
                        { label: t("param.hubspotcontact"), value: 'contacts' },
                        { label: t("param.hubspotproducts"), value: 'products' },
                        { label: t("param.hubspottickets"), value: 'tickets' },
                        { label: t("param.hubspotowners"), value: 'owners' }
                    ]}
                    onChange={()=>{console.info(checkboxData);console.info(props)}}
                />
            </Grid>
            <Condition when="endpoint" is="companies">
                <Grid item xs={12} key={3}>
                    <Checkboxes
                        label="Check at least one..."
                        name="proper"
                        // required={required.best}
                        data={checkboxData}
                        helperText={helperText}
                    />
                </Grid>
            </Condition>
            <Condition when="endpoint" is="contacts">
            <Grid item xs={12} key={3}>
                    <Checkboxes
                        label="Check at least one..."
                        name="pouet"
                        // required={required.best}
                        data={checkboxData}
                        helperText={helperText}
                    />
                </Grid>
            </Condition>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.hubspotproperties")}
                    name="properties"
                    id="properties"
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <Select
                    label={t('param.method')}
                    name="method"
                    data={[
                        { label: '', value: '' },
                        { label: 'GET', value: 'GET' },
                        { label: 'POST', value: 'POST' },
                        { label: 'PUT', value: 'PUT' },
                        { label: 'DELETE', value: 'DELETE' },
                        { label: 'PATCH', value: 'PATCH' }
                    ]}
                />
            </Grid>
            <Grid item xs={12} key={2}>
                <CustomTextField
                    label={t("param.hubspotdatas")}
                    name="datas"
                    id="datas"
                />
            </Grid>
            <Grid item xs={12} key={5}>
                <Checkboxes
                    name="okfulldatas"
                    formControlProps={{ margin: 'none' }}
                    data={{ label: t("param.okfulldatas"), value: false }}
                />
            </Grid>
        </React.Fragment>
    );
}

export default TuHubspotCompanies;