import React, { Component } from 'react';
import { Route, Link, Switch } from "react-router-dom";
import * as ROUTES from '../config/routes';
import Layout from '../components/Layout/Layout';
import DiagramBuilder from './DiagramBuilder/DiagramBuilder';
import RegularPage from './RegularPage/RegularPage';
import axios from "../axios-base";
import PasswordForgetPage from "../components/PasswordForget/PasswordForget";
import AccountPage from "../components/AccountManagement/AccountManagement";
import AdminAccounts from "../components/AdminAccounts/AdminAccounts";
import Login from "../components/Login/Login";
import withAuthentication from '../components/Session/withAuthentification/withAuthentification';
import Scenarios from "../components/Scenarios/Scenarios";
import Clients from "../components/Clients/Clients";
import Connexions from '../components/Connexions/Connexions';
import * as ROLES from "../config/roles";
import AuthUserContext from "../components/Session/Session";


class App extends Component {
    static contextType = AuthUserContext;
    state = {
        isAuth: false,
        authUser: null,
        user: null,
        isAdmin: true,
        client: { id: 0, name: null, logo: null },
        clients: null,
        Scenarios: false,
        Scenario: false,
        jsonScenario: [],
        fluxParams:null,
        allTuSchemas: null,
        environment: "admin"
    };

    componentDidMount() {
        this.getAllClients();

    };

    afficheScenario = (newJsonScenario, fluxParams) => {
        this.setState({
            jsonScenario: newJsonScenario,
            fluxParams:fluxParams
        });
    };

    getAllClients = () => {
        axios.get('/getCustomers')
            .then(response => {
                const clients = response.data;
                this.setState({ clients: clients });

            })
            .catch(error => {
                console.log(error);
                return null;
            });

        /*{'id':1, 'name':'Test'};
        const fakeClients = [{ id: 3, company: 'AGR', logo: 'wittenstein' },
        { id: 2, company: 'BUISINE', logo: 'fastcooling' },
        { id: 6, company: 'Derma Trucks', logo: 'wittenstein' },
        { id: 8, company: 'MaSolution Dev', logo: 'wittenstein' },
        { id: 5, company: 'MY RETAIL BOX', logo: 'wittenstein' },
        { id: 7, company: 'Objetrama', logo: 'wittenstein' },
        ];*/
    };

    loadClient = (clientDatas, allTuSchema) => {
        this.setState({ client: clientDatas, theme: 'client' });
    };

    render() {
        const menuFunctions = {
            showDiag: this.afficheScenario,
            isAdmin: this.state.isAdmin
        };

        const diagram = <DiagramBuilder jsonScenario={this.state.jsonScenario}
            client={this.state.client}
            fluxParams={this.state.fluxParams}
            allTuSchemas={this.state.allTuSchemas} />;

        const page = <RegularPage clients={this.state.clients}
            client={this.state.client}
            Scenarios={this.state.Scenarios}
            showClient={this.loadClient}
            showScenarios={this.afficheScenarios}
            showScenario={this.afficheScenario} />;


        const scenarios = <RegularPage>
            <Scenarios client={this.state.client} show={this.afficheScenario} />
        </RegularPage>;

        const connexions = <RegularPage>
            <Connexions client={this.state.client}  />
        </RegularPage>;


        const clients = <AuthUserContext.Consumer>
            {
                authUser => {
                    if (authUser) {
                        if (!!authUser.roles[ROLES.ADMIN])
                            return <RegularPage><Clients show={this.loadClient} userId={authUser.uid} /></RegularPage>;
                    }
                }
            }
        </AuthUserContext.Consumer>;
        //const clients = <RegularPage><Clients clients={this.state.clients} show={this.loadClient} /></RegularPage>;

        return (
            <Switch>
                /*LOGIN*/
                <Route exact path={ROUTES.LANDING} render={() => <Login clickSubmit={() => this.setIsAuth()} />} />

                <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />

                /*ADMIN PAGES*/
                <Route exact path={ROUTES.ADMIN_CLIENTS} render={() => <Layout buttonClics={menuFunctions} theme={'admin'} profil={this.state.client}>
                    {clients}
                </Layout>} />

                <Route exact path={ROUTES.ADMIN_ACCOUNTS}
                    render={() => <Layout buttonClics={menuFunctions} theme={'admin'} profil={this.state.client}>
                        <AdminAccounts />
                    </Layout>} />

                <Route exact path={ROUTES.ADMIN_ACCOUNT} render={() => <Layout buttonClics={menuFunctions} theme={'admin'} profil={this.state.client}>
                    <RegularPage>
                        <AccountPage />
                    </RegularPage>
                </Layout>} />

                /* CLIENT PAGES */
                <Route path={ROUTES.SCENARIOS} render={() => <Layout buttonClics={menuFunctions} theme={'client'} profil={this.state.client}>
                    {scenarios}
                </Layout>} />

                <Route path={ROUTES.CONNEXIONS} render={() => <Layout buttonClics={menuFunctions} theme={'client'} profil={this.state.client}>
                    {connexions}
                </Layout>} />

                <Route exact path={ROUTES.DIAGRAM} render={() => <Layout buttonClics={menuFunctions} theme={'client'} profil={this.state.client}>
                    {diagram}
                </Layout>} />

                <Route exact path={ROUTES.ACCOUNT} render={() => <Layout buttonClics={menuFunctions} theme={'client'} profil={this.state.client}>
                    <RegularPage>
                        <AccountPage />
                    </RegularPage>
                </Layout>} />
                <Route render={() => <Login />} />
            </Switch>
        );
    }

}

export default withAuthentication(App);
