import React from 'react';
import './RegularPage.css';
import Scenarios from '../../components/Scenarios/Scenarios';
import Clients from '../../components/Clients/Clients';

const regularPage = (props) => {
    let page = false;

    if(props.clients) {
        page = <Clients clients={props.clients} show={props.showClient} />
    }

    if(props.Scenarios) {
        page = <Scenarios show={props.showScenario} client={props.client}/>
    }


    return (
        <div className="container-fluid regular-page">
            {(page) ? page : props.children}
        </div>
    );
};

export default React.memo(regularPage);