import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../UI/Modal/Modal';
import { withFirebase } from '../../Firebase';

import FormOFC from '../../UI/Forms/FormOFC';
import * as Yup from 'yup';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';

import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import axios from "../../../axios-base";


// TODO : implement this class
const INITIAL_STATE = {
    clientId: null,
    id: null,
    name: '',
    guid: '',
    responseDatas:'',
    responseType:'',
    error: null,
};
const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class ToolboxParams extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount () {
        this.setState({...this.props.toolboxParamsInfos});
    }

    onSubmit = event => {
        const { clientId, id, name, guid, responseDatas, responseType } = this.state;
        if(id) {
            axios.post('/updateFlux/' + guid,{name:name, response_type:responseType, response_datas:responseDatas})
                .then(response => {
                    this.props.firebase
                        .scenario(clientId, id)
                        .update({
                            name,
                            responseDatas,
                            responseType
                        })
                        .then(() => {
                            console.log(response.data);
                            this.props.updateFluxParams(this.state);
                        })
                        .catch(error => {
                            this.setState({ error });
                        });
                })
                .catch(error => {
                    this.setState({ error });
                });
        }
        //event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            name,
            guid,
            responseDatas,
            responseType,
            error
        } = this.state;
        const {t} = this.props;

        const helperText = '* Requis';

        const isInvalid =
            name === '';

        const returnTypeValues = ["","json","xml"].map(itKey => {
            return {label: itKey, value: itKey};

        });

        const formFields = <React.Fragment>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t('toolbox.nom')}
                    name="name"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={name}
                />
            </Grid>
            <Grid item xs={12} key={1}>
                <TextField
                    disabled
                    label="GUID"
                    name="guid"
                    value={guid}
                />
            </Grid>
            <Grid item xs={12} key={1}>
                <TextField
                    label={t('toolbox.responseDatas')}
                    name="responseDatas"
                    required={required.responseDatas}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={responseDatas}
                />
            </Grid>
            <Grid item xs={12} key={1}>
                <Select
                    onChange={this.onChange}
                    label={t('toolbox.responseType')}
                    name="responseType"
                    required={required.responseType}
                    data={returnTypeValues}
                    multiple={false}
                    value={responseType}
                />
            </Grid>
        </React.Fragment>;


        /*const formFields = [
            {
                size: 12,
                field: (
                    <TextField
                        label="Nom"
                        name="name"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={name}
                    />)
            },
            {
                size: 12,
                field: (
                    <TextField
                        disabled
                        label="GUID"
                        name="guid"
                        value={guid}
                    />)
            },
            {
                size: 6,
                field: (
                    <TextField
                        label="Donnée renvoyée"
                        name="responseDatas"
                        required={required.responseDatas}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={responseDatas}
                    />)
            },
            {
                size: 6,
                field: (
                    <Select
                        onChange={this.onChange}
                        label="Type de donnée"
                        name="responseType"
                        required={required.responseType}
                        data={returnTypeValues}
                        multiple={false}
                        value={responseType}
                    />)
            }

        ];*/


        return (

            <React.Fragment>
                <h1>Editez le flux </h1>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         closePopup={this.props.closePopup}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
        );
    }
}


const CompleteToolboxParams = withRouter(withFirebase(withTranslation('diagram')(ToolboxParams)));

export default CompleteToolboxParams;