import React, { Component } from 'react';
//import Diagram from '../../components/Diagram/Diagram';
import './DiagramBuilder.css';
import Parametres from '../../components/Parametres/Parametres';
import {
    CanvasWidget, DeleteItemsAction
} from '@projectstorm/react-canvas-core';
import createEngine, {
    // DefaultLinkModel,    
    DiagramModel,
} from '@projectstorm/react-diagrams';

import { OFCNodeFactory } from '../../components/Diagram/OFCNode/OFCNodeFactory';
import { OFCNodeModel } from '../../components/Diagram/OFCNode/OFCNodeModel';
import { OFCSuperNodeFactory } from '../../components/Diagram/OFCSuperNode/OFCSuperNodeFactory';
import { OFCSuperNodeModel } from '../../components/Diagram/OFCSuperNode/OFCSuperNodeModel';
import { OFCLinkFactory } from '../../components/Diagram/OFCLink/OFCLinkFactory';
import OFCLinkModel from '../../components/Diagram/OFCLink/OFCLinkModel';
import { EditableLabelFactory } from '../../components/Diagram/OFCLabel/EditableLabelFactory';
import { EditableLabelModel } from '../../components/Diagram/OFCLabel/EditableLabelModel';

import Toolbox from '../../components/Toolbox/Toolbox';
import Chip from '../../components/Chip/Chip';
import axios from '../../axios-base';
import StructureProvider, { StructureContext } from '../../components/Parametres/Structure/StructureContext';
import typeReader from '../../components/Diagram/ReadTypeTU';
import { withFirebase } from "../../components/Firebase";
import { OFCPortFactory } from '../../components/Diagram/OFCLink/OFCPortFactory';
import { withTranslation } from 'react-i18next';

class DiagramBuilder extends Component {
    // const { structureList, setStructureList } =  useContext(StructureContext);

    state = {
        //listeTu: '[{"id": 22,"id_tu": 13,"name_tu": "lecture CSV article edikett","ordre": 1},{"id": 24,"id_tu": 4,"name_tu": "génere json csv article","ordre": 2},{"id": 26,"id_tu": 3,"name_tu": "appel url edikett","ordre": 3},{"id": 32,"id_tu": 3,"name_tu": "toto 4","ordre": 4}]',
        listeTu: (this.props.jsonScenario && this.props.jsonScenario.scenarioSteps) ? this.props.jsonScenario.scenarioSteps : [],
        okparam: false,
        paramType: null,
        engine: null,
        saving: false,
        allSchemas: this.props.allTuSchemas,
        fluxParams: this.props.fluxParams
    };
    /*constructor (props) {
        super(props);
        this.state = {
            listeTu: '[{"id": 22, "id_tu": 13,"name_tu": "lecture CSV article edikett","ordre": 1},' +
            '{"id": 24,"id_tu": 4,"name_tu": "génere json csv article","ordre": 2},{"id": 26,"id_tu": 3,"name_tu": "appel url edikett","ordre": 3},{"id": 32,"id_tu": 3,"name_tu": "toto 4","ordre": 4}]',
            okparam: false,
            paramType: null,
            engine: this.getengine(),
        };
        if(this.props.jsonScenario.scenarioSteps) {
            console.log(this.props.jsonScenario.scenarioSteps);
            this.setState({listeTu: this.props.jsonScenario.scenarioSteps});
        }
    }*/

    componentDidMount() {
        //this.getFluxParams();
        this.getengine();
        /*const engineTMP = this.getengine();
        this.setState({
            engine: engineTMP
        });*/
    };

    getFluxParams() {
        this.props.firebase.scenario(this.props.client.id, this.props.jsonScenario.id).on('value', snapshot => {
            const registeredParams = snapshot.val();
            // console.log(registeredParams);
            this.setState({
                fluxParams: registeredParams
            });
        });
    };
    updateFluxParams = (newParams) => {
        this.setState({ fluxParams: newParams });
    };

    updateListeTu = (newList) => {
        if (newList !== this.state.listeTu) {
            this.setState({ listeTu: newList });
        }
    };

    loadScenario = () => {

        //this.state.engine.getModel().deserializeModel(JSON.parse(str), engine);
    };

    importScenario = (formdata) => {
        const datasimport = JSON.parse(formdata.jsondatas);
        console.log(datasimport);
        const engine = this.state.engine;
        let model = this.state.engine.getModel();
        model.deserializeModel(datasimport.model, engine);
        model.getNodes().forEach((node, index) => {
            node.setAfficheParam(this.afficheparam);
            node.setRemoveModel(() => this.removeModel(node));
            node.setUpdateName(this.updateTuName);
            if (!node.options.id_node) node.options.id_node = node.options.params.ordre;
            if (!node.options.ordre) node.options.ordre = node.options.params.ordre;
            if (!node.options.id_boucle && node.options.params && node.options.params.id_boucle) node.options.id_boucle = node.options.params.id_boucle;
        });
        model.getLinks().forEach((link, index) => { if (link.labels.length > 1) link.labels.pop(); })
        engine.setModel(model);
        this.context.setStructureList(datasimport.structure);
    };

    exportScenario = () => {
        const jsonData = new Object();
        jsonData.model = this.state.engine.getModel().serialize();
        jsonData.structure = this.context.structureList;
        return JSON.stringify(jsonData);
    };

    saveScenario = () => {
        this.setState({ saving: true });
        // console.log(this.state.engine.getModel());
        // console.log(this.props);
        let nodes = this.state.engine.getModel().getNodes();
        // let toto = nodes[0].options;
        // console.log(toto);
        let scenario = new Object();
        scenario.id = this.props.jsonScenario.id;
        scenario.params = new Array();
        nodes.forEach((step, index) => {
            let traitementUnitaire = new Object();
            traitementUnitaire.name = step.options.name;
            if (!step.options.id_node) traitementUnitaire.id_node = step.options.params.ordre;
            else traitementUnitaire.id_node = step.options.id_node;
            if (!step.options.ordre) traitementUnitaire.ordre = step.options.params.ordre;
            else traitementUnitaire.ordre = step.options.ordre;
            traitementUnitaire.id_boucle = step.options.id_boucle;
            traitementUnitaire.type = step.options.typeTU;
            traitementUnitaire.params = step.options.params;
            scenario.params.push(traitementUnitaire);
            if (step.options.typeTU == 11) {
                step.options.children.forEach(child => {                    
                    let traitementUnitaire = new Object();
                    traitementUnitaire.name = child.name;
                    if (!child.id_node) traitementUnitaire.id_node = child.params.ordre;
                    else traitementUnitaire.id_node = child.id_node;
                    if (!child.ordre) traitementUnitaire.ordre = child.params.ordre;
                    else traitementUnitaire.ordre = child.ordre;
                    traitementUnitaire.id_boucle = step.options.id_node;
                    traitementUnitaire.type = child.typeTU;
                    traitementUnitaire.params = child.params;
                    scenario.params.push(traitementUnitaire);
                });
            }
        });
        console.log(scenario);
        axios.post('/savesteps', JSON.stringify(scenario)).then(response => {

        }).catch(error => {
            console.log(JSON.stringify(scenario));
            console.log(error);
        });

        // console.log(scenario);
        //let jsonTU = this.state.listeTu;
        const jsonTUtmp = this.state.engine.getModel().serialize();
        // console.log(this.state.engine.getModel());
        /*const jsonTU = JSON.parse(JSON.stringify(jsonTUtmp, function(k, v) {
            if (v === undefined) { return 'undefined*'; } return v;
        }));*/
        const jsonTU = JSON.stringify(jsonTUtmp);
        // console.log(jsonTU);
        this.props.firebase.scenario(this.props.client.id, this.props.jsonScenario.id)
            .update({
                diagram: jsonTU
            })
            .then(() => {
                this.setState({ saving: false });
            })
            .catch(error => {
                this.setState({ saving: false });
                console.log(error);
            });
        // console.log(this.context.structureList);
        // console.log(JSON.stringify(this.context.structureList));
        this.props.firebase.scenario(this.props.client.id, this.props.jsonScenario.id)
            .update({
                structure: JSON.stringify(this.context.structureList)
            })
            .catch(error => {
                console.log(error);
            });
        return true;
    };

    updateTuName = (id, newName) => {
        /*let newListeTu = [...this.state.listeTu];
        newListeTu.forEach(element => {
            if (element.id === id) {
                element.name = newName;
                this.setState({ listeTu: newListeTu });
                // console.log(id);
            }
        });*/
        const currNode = this.state.engine.getModel().getNode(id);
        if(currNode) {
            currNode.options.name = newName;
        }
        /*this.state.engine
            .getModel()
            .getNodes().forEach(element => {
                if (element.options.id === id) {
                    element.options.name = newName;
                }
            })*/
    };

    updateTuOrdre = (id, newOrdre) => {
        /*this.state.engine
            .getModel()
            .getNodes().forEach(element => {
            if (element.options.id === id) {
                element.options.ordre = newOrdre;
                this.updateNextOrdre(element);
            }
        });*/

        const currNode = this.state.engine.getModel().getNode(id);
        if(currNode) {
            currNode.options.ordre = newOrdre;
            this.updateNextOrdre(currNode);
        }
    };

    updateNextOrdre = (node) => {
        const nextNodeId = node.getNext();
        if(nextNodeId) {
            const position = node.options.ordre;
            const nextNodeObject = this.state.engine.getModel().getNode(nextNodeId);
            if(nextNodeObject) {
                nextNodeObject.options.ordre = position + 1;
                this.updateNextOrdre(nextNodeObject);
            }
        }
    };

    getSchema = (type) => {
        let schema = null;
        const schemaArray = this.state.allSchemas.slice();
        schemaArray.forEach(element => {
            if (element.id === type) {
                schema = element;
            }
        });
        return schema;
    };

    executeTU = (id_node) => {
        if (!id_node)
            return;
        if (this.saveScenario()) {
            let url = axios.defaults.baseURL;
            url = url + 'lance/partialscenario/' + this.state.fluxParams.guid + '/' + id_node;
            console.log(url);
            window.open(url, "_blank");
        }
    };

    getLastOrdre = () => {
        //TODO : retourner un tableau contenant le last ordre + last id
        const nodes = this.state.engine.getModel().getNodes();
        let nodesCount = 0;
        nodes.forEach((step, index) => {
            if (step.options.typeTU === 11) {
                if (step.options.children.length > 0)
                    step.options.children.forEach(
                        (step, index) => {
                            if (step.ordre > nodesCount) nodesCount = step.ordre;
                        }
                    );
            }
            if (step.options.ordre > nodesCount) nodesCount = step.options.ordre;
        });
        return nodesCount + 1;
    };


    getNewIdNode = () => {
        const nodes = this.state.engine.getModel().getNodes();
        let nodesCount = 0;
        nodes.forEach((step, index) => {
            if (step.options.typeTU === 11) {
                console.log(step.options);
                if (step.options.children.length > 0)
                    step.options.children.forEach(
                        (step, index) => {
                            if (step.id_node > nodesCount) nodesCount = step.id_node;
                        }
                    );
            }
            if (step.options.id_node > nodesCount) nodesCount = step.options.id_node;
        });
        return nodesCount + 1;
    };


    createNewTU = (type) => {
        // this.state.engine.getModel().getLinks().forEach((link, index) => {
        //     link.addLabel(new EditableLabelModel({
        //      value: 'Hello, I am label!'
        //  }));
        //  });
        const newNode = {
            id: 0,
            id_module: null,
            id_traitement_unitaire: { id: 1, name: "Parse JSON", class_name: "TuJSON" },
            ordre: 0,
            name: "Nouveau traitement",
            id_boucle: 0,
            params: { id: 1, idStep: 10, idScenario: 3, idModule: null, ordre: "1", name: "Parse JSON From CS", datas: "¤request.content" },
            schema: { title: "TuJSON", type: "object", properties: { idStep: { type: "integer" }, idScenario: { "type": "integer" }, idModule: { "type": "integer" }, ordre: { "type": "string" }, name: { "type": "string" }, datas: { "type": "string" } }, required: ["ordre", "name", "datas"] }
        };

        const {t} = this.props;
        let typeTU = typeReader(type, this.props.typesTrans);
        //const newNode = this.getSchema(type);
        // console.log(newNode);
        // console.log(type);

        let newListeTu = [...this.state.listeTu];

        const nodes = this.state.engine.getModel().getNodes();
        let newId = this.getNewIdNode();
        let boucleCount = 0;
        nodes.forEach((step, index) => {
            if (step.options.id_boucle > 0) boucleCount = boucleCount + 1;
        });

        // const nodesCount = this.state.engine
        //     .getModel()
        //     .getNodes()
        //     .length;
        //const j = nodesCount;
        newListeTu.push(newNode);
        this.setState({ listeTu: newListeTu });

        let node = null;
        //Si le type est un itérateur -> Super Node
        if (type === 11) {
            node = new OFCSuperNodeModel({
                key: newId,
                name: t(typeTU['name']),
                typeTU: type, //newNode.id,
                ordre: 1,
                id_node: newId,
                id_boucle: boucleCount,
                color: 'rgb(255,192,255)',
                params: {},
                schema: {}, //newNode.schema,
                fctparam: this.afficheparam,
                fctexecute: this.executeTU,
                getpos: () => this.affichepos(node),
                removeModel: () => this.removeModel(node),
                updateName: this.updateTuName,
                updateOrdre: this.updateTuOrdre,
                getLastOrdre: this.getLastOrdre,
                getLastIdNode: this.getNewIdNode
            });
        } else {
            node = new OFCNodeModel({
                key: newId,
                name: t(typeTU['name']),
                typeTU: type, //newNode.id,
                ordre: 1,
                id_node: newId,
                id_boucle: 0,
                color: 'rgb(255,192,255)',
                params: {},
                schema: {}, //newNode.schema,
                fctparam: this.afficheparam,
                fctexecute: this.executeTU,
                getpos: () => this.affichepos(node),
                removeModel: () => this.removeModel(node),
                updateName: this.updateTuName,
                updateOrdre: this.updateTuOrdre
            });
        }

        node.setPosition(150 + 150 * this.state.engine.getModel().getNodes().length, 150);
        let nodetu = {
            "node": node,
            "in": node.getPort('in'),
            "out": node.getPort('out'),
            "link": {}
        };

        this.state.engine
            .getModel()
            .addNode(node);
    };

    afficheparam = (newParamType, Name, newParams, newSchema, keynode, idNode) => {
        console.log(newSchema);
        console.log(newParams);
        // console.log(this.context.structureList);
        this.setState(prevState => ({ okparam: !prevState.okparam }));

        if (this.paramType !== newParamType)
            this.setState({ paramType: newParamType });
        this.setState({ 'idNode': idNode, 'schema': newSchema, 'params': newParams, 'keynode': keynode, 'paramName': Name });
    };

    sauveparam = (keynode, formData) => {
        let models = this.state.engine.getModel();
        let node = this.state.engine.getModel().getNode(keynode);
        // console.log(node.options.params);
        // console.log(formData);
        console.log(node.options);
        console.log(this.state.idNode);
        if (node.options.typeTU === 11 && node.options.id_node != this.state.idNode) {
            node.options.children.forEach(step => {
                if (step.id_node === this.state.idNode) {
                    step.params = formData;
                }
            });
        }

        // if(node.options.typeTU !== 11) {
        else {
            node.options.params = formData;
        }
        this.state.engine.setModel(models);
    };

    affichepos = (node) => {
        // console.log(node.getPosition());

        // console.log(this.state.listeTu);
    };

    getengine = () => {
        //this.props.firebase.diagram(this.props.client.id, this.props.jsonScenario.id).on('value', snapshot => {
        this.props.firebase.diagram(this.props.client.id, this.props.jsonScenario.id).once('value').then(snapshot => {

            const scenarioData = snapshot.val();
            const diagSchema = (scenarioData) ? scenarioData : null;
            let engineTMP = null;
            engineTMP = this.initEngine();
            this.setState({
                engine: engineTMP
            });
            if (diagSchema) {
                /*const jsonTU = JSON.parse(JSON.stringify(diagSchema, function(k, v) {
                    if (v === 'undefined*') { return undefined; } return v;
                }));*/
                const jsonTU = JSON.parse(diagSchema);
                // console.log(jsonTU);
                engineTMP = this.buildEngine(jsonTU);

            } else
                engineTMP = this.buildEngine(diagSchema);

            this.setState({
                engine: engineTMP
            });
        });

        this.props.firebase.structure(this.props.client.id, this.props.jsonScenario.id).once('value').then(snapshot => {
            if (snapshot.val()) this.context.setStructureList(JSON.parse(snapshot.val()));
            else this.context.setStructureList({});
        });
        /*firebase.get('/scenario/' + this.props.jsonScenario.id + '/diagram.json')
            .then(response => {
                const engineTMP = this.buildEngine(response.data);
                this.setState({
                    engine: engineTMP
                });
                //return this.buildEngine(response.data);
            })
            .catch(error => {
                this.setState({ saving: false });
                console.log(error);
            });*/

        //return this.buildEngine(null);

    };

    removeModel = (model) => {
        if (!model.isLocked()) {
            model.remove();
        }

        this.state.engine.repaintCanvas();
    };

    initEngine = () => {
        const engine = createEngine({ registerDefaultDeleteItemsAction: false });

        //SET number of point to 0 to avoid point creation.
        engine.maxNumberPointsPerLink = 0;

        // register OFC Node
        engine.getPortFactories().registerFactory(new OFCPortFactory());
        engine.getNodeFactories().registerFactory(new OFCNodeFactory());
        engine.getNodeFactories().registerFactory(new OFCSuperNodeFactory());
        engine.getLinkFactories().registerFactory(new OFCLinkFactory());

        engine.getLabelFactories().registerFactory(new EditableLabelFactory());

        const model = new DiagramModel();
        engine.setModel(model);
        return engine;
    };

    buildEngine = (jsonData) => {

        //let tuarray = JSON.parse(this.state.listeTu);
        let tuarray = this.state.listeTu;
        //let tuarray = this.props.jsonScenario.scenarioSteps;
        const engine = this.state.engine;//createEngine({ registerDefaultDeleteItemsAction: false });

        //SET number of point to 0 to avoid point creation.
        // engine.maxNumberPointsPerLink = 0;

        // register OFC Node        
        engine.getPortFactories().registerFactory(new OFCPortFactory());
        engine.getNodeFactories().registerFactory(new OFCNodeFactory());
        engine.getNodeFactories().registerFactory(new OFCSuperNodeFactory());
        engine.getLinkFactories().registerFactory(new OFCLinkFactory());
        engine.getLabelFactories().registerFactory(new EditableLabelFactory());

        const model = new DiagramModel();


        model.registerListener({
            //nodesUpdated: e => console.log("nodesUpdated", e),
            linksUpdated: e => {
                //on contrôle s'il s'agit d'une suppression
                if((e.isCreated === false) && e.link.getTargetPort()) {
                    const sourceNode = e.link.getSourcePort().getNode();
                    if(sourceNode) {
                        sourceNode.options.next_node = null;
                    }

                    const targetNode = e.link.getTargetPort().getNode();
                    if (targetNode) {
                        targetNode.setOrdre(1);
                        targetNode.options.previous_node = null;
                    }
                }
            }
        });

        if (jsonData) {
            model.deserializeModel(jsonData, engine);
            model.getNodes().forEach((node, index) => {
                node.setAfficheParam(this.afficheparam);
                node.setExecute(this.executeTU);
                node.setRemoveModel(() => this.removeModel(node));
                node.setUpdateName(this.updateTuName);
                node.setUpdateOrdre(this.updateTuOrdre);
                if (node.options.typeTU === 11) {
                    node.setfctgetLastOrdre(this.getLastOrdre);
                    node.setfctgetLastIdNode(this.getNewIdNode);
                }
                if (!node.options.id_node) node.options.id_node = node.options.params.ordre;
                if (!node.options.ordre) node.options.ordre = node.options.params.ordre;
                if (!node.options.id_boucle && node.options.params && node.options.params.id_boucle) node.options.id_boucle = node.options.params.id_boucle;
            });
            // console.log()
            model.getLinks().forEach((link, index) => { if (link.labels.length > 1) link.labels.pop(); })
            engine.setModel(model);

        } else {
            // model.addAll(node1, node2, link);

            let nodearray = [];
            let j = 0;

            for (var i in tuarray) {
                let obj = tuarray[i];
                let node = new OFCNodeModel({
                    key: obj.id,
                    name: obj.name,
                    typeTU: obj.id_traitement_unitaire.id,
                    params: obj.params,
                    schema: obj.schema,
                    color: 'rgb(255,192,255)',
                    id_node: obj.id_node,
                    ordre: obj.ordre,
                    id_boucle: obj.id_boucle,
                    fctparam: this.afficheparam,
                    fctexecute: this.executeTU,
                    removeModel: () => this.removeModel(node),
                    getpos: () => this.affichepos(node),
                    updateName: this.updateTuName,
                    updateOrdre: this.updateTuOrdre
                });
                node.setPosition(150 + 150 * j, 150);
                let nodetu = {
                    "node": node,
                    "in": node.getPort('in'),
                    "out": node.getPort('out'),
                    "link": {}
                };

                model.addNode(nodetu.node);
                nodearray[j] = nodetu;
                if (j > 0) {
                    // console.log(nodearray[j]);
                    let nodeout = nodearray[j - 1].out;
                    let link = new OFCLinkModel();
                    link.setSourcePort(nodearray[j - 1].out);
                    link.setTargetPort(nodearray[j].in);
                    //let link = nodearray[j-1].out.link<DefaultLinkModel>(nodearray[j].in);
                    link.addLabel(new EditableLabelModel({
                        value: 'Hello, I am label!'
                    }));
                    model.addLink(link);
                }
                j++;
                // nodearray[obj.id] = (new DefaultNodeModel({
                //       name: obj.name_tu,
                //       color: 'rgb(0,192,255)',
                //     }));
                // portarray[obj.id] = node1.addOutPort('Out');
                // model.addNode(nodearray[obj.id]);
            }

            engine.setModel(model);
        }
        engine.getActionEventBus().registerAction(new DeleteItemsAction({ keyCodes: [46], modifiers: { ctrlKey: true } }));
        return engine;
    };

    render() {
        return (

            <React.Fragment>
                <div className="nomflux row">
                    <p className={"flux_title-item client-name"}>{this.props.client.name} </p>
                    <Chip start={this.state.fluxParams.chipStart} end={this.state.fluxParams.chipEnd} />
                    <p className={"flux_title-item"} style={{ "fontStyle": "italic" }}> {this.props.jsonScenario.name}</p>
                </div>
                {this.state.engine ? <CanvasWidget className="ofc-diagram" engine={this.state.engine} /> : null}

                {this.state.okparam ? <Parametres afficheParam={this.afficheparam}
                    paramType={this.state.paramType}
                    paramName={this.state.paramName}
                    schema={this.state.schema}
                    params={this.state.params}
                    keynode={this.state.keynode}
                    idnode={this.state.idNode}
                    sauveparam={this.sauveparam} /> : null}
                <Toolbox importScenario={this.importScenario} exportScenario={this.exportScenario} createNode={this.createNewTU} saveDiag={this.saveScenario} saving={this.state.saving} toolboxParamsInfos={this.state.fluxParams} updateFluxParams={this.updateFluxParams} />
            </React.Fragment>
        );
    }
}
DiagramBuilder.contextType = StructureContext;

export default withFirebase(withTranslation('diagram')(DiagramBuilder));