import React, { Component } from 'react';

import RegularPage from './../../containers/RegularPage/RegularPage';
import { SignUpForm } from './../SignUp/SignUp';
import EditAccount from './../EditAccount/EditAccount';
import UserList from './../EditAccount/UserList/UserList';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withAuthorization from '../Session/withAuthorization/withAuthorization';
import * as ROLES from '../../config/roles';
import Modal from "../UI/Modal/Modal";
import { withTranslation } from 'react-i18next';

class AdminPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            registering:false,
            editing:false,
            userInfo:null,
            users: [],
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.users().on('value', snapshot => {
            const usersObject = snapshot.val();

            const usersList = Object.keys(usersObject).map(key => ({
                ...usersObject[key],
                uid: key,
            }));

            this.setState({
                users: usersList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
    }

    isRegistering() {
        this.setState({registering:true});
    }

    unregister() {
        this.setState({registering:false});
    }

    isEditing = (userInfo) => {
        this.setState({userInfo:userInfo, editing:true});
    };

    unedit() {
        this.setState({userInfo:null, editing:false});
    }

    render() {
        const { users, loading } = this.state;
        const { t } = this.props;

        return (
            <RegularPage>
                <div className="jumbotron">
                    <h1 className="display-4">{t("admComptes.titre")}</h1>
                    <hr className="my-4"/>


                {loading && <div>{t('loading')}</div>}

                <UserList users={users} edit={this.isEditing}/>
                <p onClick={() => this.isRegistering()} className={"cursor"}>{t("admComptes.ajout")}</p>

                </div>
                <Modal show={this.state.registering} modalClosed={() => this.unregister()}>
                    <SignUpForm />
                </Modal>
                <Modal show={this.state.editing} modalClosed={() => this.unedit()}>
                    {this.state.userInfo && <EditAccount userInfo={this.state.userInfo} />}
                </Modal>

            </RegularPage>
        );
    }
}

const TranslateAdminPage = withTranslation()(AdminPage);

const condition = authUser => authUser && authUser.roles && !!authUser.roles[ROLES.ADMIN];

export default compose(
    withAuthorization(condition),
    withFirebase,
)(TranslateAdminPage);