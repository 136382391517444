import React from "react";
import {CatalogTypesNonTranslated} from './CatalogTypesTranslated';

function ReadTypeTU (typeId, typesT = null) {
   let type = null;
   let types = typesT;
   if (!types)
     types = CatalogTypesNonTranslated();

    if (typeId in types) {
        type = types[typeId];
    }

    return type;
};

export default ReadTypeTU;