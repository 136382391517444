import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { FieldArray } from "react-final-form-arrays";
import { TextField} from 'mui-rff';
import {useTranslation} from "react-i18next";


const CustomFieldArrayNoPopUp = (props) => {
    const { t } = useTranslation('diagram');

    return (
        <Grid container spacing={1}>
            <FieldArray name={props.name}>
                {({ fields }) => (
                    <div>
                        <Grid item xs={12} ><label>{props.label}</label> </Grid>
                        <Grid item xs={12} >
                            <button
                                type="button"
                                onClick={() => fields.push( undefined)}
                            >
                                {props.addlabel}
                        </button>
                        </Grid>
                        {fields.map((name, index) => (
                            <Grid container alignItems="flex-start" spacing={2} key={name}>
                                {/* <label>Cust. #{index + 1}</label> */}
                                <Grid item xs={4} >
                                    <TextField
                                        name={`${name}.name`}
                                        placeholder="Name"
                                        label={t('param.name')}
                                    />
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField
                                        name={`${name}.value`}
                                        placeholder="Value"
                                        label={t('param.value')}
                                        id={`${name}.value`}
                                    />
                                </Grid>
                                <Grid item xs={2} >
                                    <span
                                        onClick={() => fields.remove(index)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        ❌
                                </span>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                )}
            </FieldArray>
        </Grid>
    );
}

export default CustomFieldArrayNoPopUp;