import {NodeModel } from '@projectstorm/react-diagrams';
import {OFCPortModel} from '../OFCLink/OFCPortModel';

import {TranslationContext} from "../../../context/TranslationContext";

/**
 * Example of a custom model using pure javascript
 */
export class OFCSuperNodeModel extends NodeModel {

    constructor(options = {}) {
		super({
			...options,
			type: 'ofc-super-node',
            typeTU: options.typeTU,
            key: options.key,
			name: options.name,
			id_node: options.id_node,
			ordre: options.ordre,
			id_boucle: options.id_boucle,
			children: (options.children) ? options.children : [],
            previous_node: options.previous_node,
            next_node: options.next_node,
		});
		this.color = options.color || { options: 'red' };

		// setup an in and out port
		this.addPort(
			new OFCPortModel({
				in: true,
				name: 'in'
			})
		);
		this.addPort(
			new OFCPortModel({
				in: false,
				name: 'out'
			})
		);
	}

    getPrev = () => {
        return this.options.previous_node;
    };

    getNext = () => {
        return this.options.next_node;
    };


    addChildren = (type, ordre, types) => {
        //const newOrdre = this.options.getLastOrdre();
        const newIdNode = this.options.getLastIdNode();
        //const types = this.context.types;
        let children = this.options.children.slice();

        children.push({
			name:types[type].name,
			typeTU:type,
			key:0,
			params:{},
			schema:{},
			id_node:newIdNode,
			ordre:ordre,
			id_boucle:this.options.id_boucle});
        this.options.children = children;
        return true;
    };

    removeChildren = (key) => {
        let children = this.options.children.slice();
        children.splice(key, 1);
        this.options.children = children;
        return true;
    };

    moveUp = (key) => {
        if(key > 0) {
        	let children = this.options.children.slice();
            this.swap(children, key, key-1);
			this.options.children = children;
            this.recalculOrdre();
        }

        return true;
    };

    moveDown = (key) => {
        let children = this.options.children.slice();
        if(key < children.length -1) {
            this.swap(children, key, key+1);
            this.options.children = children;
            this.recalculOrdre();
        }

        return true;
    };

    swap = (input, index_A, index_B) => {
        let temp = input[index_A];

        input[index_A] = input[index_B];
        input[index_B] = temp;
        return true;
    };

    recalculOrdre = () => {
        this.options.children.forEach(function (element, index) {
        	element.ordre = index + 1;
		});

	};

    setOrdre = (newOrdre) => {
        this.options.ordre = newOrdre;
    };

	setAfficheParam = (fct) => {
		this.options.fctparam = fct;
	};

	setfctgetLastOrdre = (fct) => {
		this.options.getLastOrdre = fct;
	};

	setfctgetLastIdNode = (fct) => {
		this.options.getLastIdNode = fct;
	};

	setExecute = (fct) => {
		this.options.fctexecute = fct;
	};

    setRemoveModel = (fct) =>{
        this.options.removeModel = fct;
    };

    setUpdateName = (fct) =>{
        this.options.updateName = fct;
	};
	
    setUpdateOrdre = (fct) =>{
        this.options.updateOrdre = fct;
    };

    updateChildName = (idNode, newName) => {
        this.options.children.map(itKey => {
        	if(itKey.id_node === idNode) {
        		itKey.name = newName;
			}
        });
	};

	serialize() {
		return {
			...super.serialize(),
			color: this.options.color,
            typeTU: this.options.typeTU,
            key: this.options.key,
			name: this.options.name,			
			id_node: this.options.id_node,
			ordre: this.options.ordre,
			id_boucle: this.options.id_boucle,
			params : this.options.params,
			schema : this.options.schema,
            children: this.options.children,
            previous_node: this.options.previous_node,
            next_node: this.options.next_node,
		};
	}

	deserialize(ob, engine) {
		super.deserialize(ob, engine);
		this.options.color = ob.data.color;
        this.options.typeTU = ob.data.typeTU;
        this.options.key = ob.data.key;
		this.options.name = ob.data.name;		
		this.options.id_node = ob.data.id_node;
		this.options.ordre = ob.data.ordre;
		this.options.id_boucle = ob.data.id_boucle;
		this.options.params = ob.data.params; 
		this.options.schema = ob.data.schema;
        this.options.children = ob.data.children;
        this.options.previous_node = ob.data.previous_node;
        this.options.next_node = ob.data.next_node;
	}
}
