import React, { Component } from 'react';

import { withFirebase } from '../Firebase/Context/Context';

import FormOFC from '../UI/Forms/FormOFC';
import * as Yup from 'yup';

import { Grid } from '@material-ui/core';
import {
    Autocomplete,
    AutocompleteData,
    Checkboxes,
    CheckboxData,
    Select,
    SelectData,
    Radios,
    RadioData,
    KeyboardDatePicker,
    DatePicker,
    Switches,
    TimePicker,
    makeValidate,
    makeRequired,
    TextField,
    Debug,
    SwitchData,
} from 'mui-rff';

import { withTranslation } from 'react-i18next';

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

const schema = Yup.object().shape({});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;

        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, error } = this.state;
        const { t } = this.props;

        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        const helperText = t("form.requis");


        const formFields = <React.Fragment>
            <Grid item xs={6} key={1}>
                <TextField
                    label={t("form.password")}
                    name="passwordOne"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={passwordOne}
                />
            </Grid>
            <Grid item xs={6} key={2}>
                <TextField
                    label={t("form.confirmPass")}
                    name="passwordTwo"
                    required={required.name}
                    helperText={helperText}
                    onChange={this.onChange}
                    value={passwordTwo}
                />
            </Grid>
        </React.Fragment>;

        /*const formFields = [
            {
                size: 6,
                field: (
                    <TextField
                        label={t("form.password")}
                        name="passwordOne"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={passwordOne}
                    />)
            },            {
                size: 6,
                field: (
                    <TextField
                        label={t("form.confirmPass")}
                        name="passwordTwo"
                        required={required.name}
                        helperText={helperText}
                        onChange={this.onChange}
                        value={passwordTwo}
                    />)
            },

        ];*/

        return (

            <React.Fragment>
                <h3>{t("admComptes.changePass")}</h3>
                <hr className="my-4"/>
                <FormOFC initialValues={this.state}
                         schema={schema}
                         formFields={formFields}
                         isValid={!isInvalid}
                         onSubmit={this.onSubmit}
                />
            </React.Fragment>
            /*<form onSubmit={this.onSubmit}>
                <input
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="New Password"
                />
                <input
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm New Password"
                />
                <button disabled={isInvalid} type="submit">
                    Reset My Password
                </button>

                {error && <p>{error.message}</p>}
            </form>*/
        );
    }
}

export default withFirebase(withTranslation()(PasswordChangeForm));